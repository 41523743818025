import React from "react";
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/icons
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

// core components
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";

import registerPageStyle from "../../assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import notificationService from "../../services/notificationService";

class UserRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
    };
  }

  handleToggleShowPw = () => {
    this.props.authStore.toggleShowPasswordUser();
  }

  handleToggleShowConfirmPw = () => {
    this.props.authStore.toggleShowConfirmPasswordUser();
  }

  hideAlert = () => {
    this.setState({
      alert: null
    });
  }

  submit = (e) => {
    e.preventDefault();
    const { userRegisterForm, registerUser } = this.props.authStore;
    // if (userRegisterForm.$('agreeTerm').value) {
      if (!userRegisterForm.isValid) {
        return userRegisterForm.showErrors(true);
      }

      userRegisterForm.submit({
        onSuccess: form => {
          const data = form.values();
          registerUser(data).then((res) => {
            console.log('userRegisterForm registerUser', res);
            if (res.code === 0) {
              notificationService.success('REGISTER.SIGNUP_SUCCESS');
            } else {
              notificationService.error(res.message);
            }
          }).catch(res => {
            console.log('error ', res);
            const err = res.response;
            notificationService.error(err.data.message);
          });
        },
        onError: (form) => {
          // eslint-disable-next-line no-console
          console.log("All form errors", form.errors());
        }
      })
    // }
  }

  render() {
    const { classes } = this.props;
    const { userRegisterForm } = this.props.authStore;
    const { currentUser } = this.props.userStore;
    const isUserRole = currentUser && currentUser.role !== 'ADMIN';

    return (
      <div className={classes.container}>
          { isUserRole ? <div> </div> :
              <GridContainer justify="center">
                  {this.state.alert}
                  <GridItem xs={12} sm={12} md={6}>
                      <Card className={classes.cardSignup}>
                          <h2 className={classes.cardTitle}>Register</h2>
                          <CardBody>
                              <div className={classes.center}>

                              </div>
                              <form className={classes.form} onSubmit={this.submit}>

                                  <GridContainer justify="center">
                                      <GridItem xs={12} sm={12} md={12}>
                                          <CustomInput
                                              formControlProps={{
                                                  fullWidth: true,
                                                  className: classes.customFormControlClasses
                                              }}
                                              inputProps={userRegisterForm.$('username').bind()}
                                              error={!!userRegisterForm.$('username').error}
                                              helpText={userRegisterForm.$('username').error || undefined}
                                              labelText='Enter your username'
                                          />
                                      </GridItem>
                                  </GridContainer>
                                  <GridContainer justify="center">
                                      <GridItem xs={12} sm={12} md={12}>
                                          <CustomInput
                                              formControlProps={{
                                                  fullWidth: true,
                                                  className: classes.customFormControlClasses
                                              }}
                                              inputProps={{
                                                  endAdornment: (
                                                      <InputAdornment
                                                          position="end"
                                                          className={classes.inputAdornment}
                                                          onClick={this.handleToggleShowPw}
                                                      >
                                                          {this.props.authStore.userRegisterForm.$("password").get("type") === "password" ? <RemoveRedEye className={classes.inputAdornmentIcon} /> :
                                                              <VisibilityOff className={classes.inputAdornmentIcon} />}
                                                      </InputAdornment>
                                                  ),
                                                  ...userRegisterForm.$('password').bind()
                                              }}
                                              error={!!userRegisterForm.$('password').error}
                                              helpText={userRegisterForm.$('password').error || undefined}
                                              labelText='Password'
                                          />
                                          <div className={classes.passwordHint}>Password must contain at least 8 characters, one lower case letter, one upper case letter, one number and one of the following symbols: !, @, #, $, %, &, *</div>
                                      </GridItem>
                                  </GridContainer>
                                  <GridContainer justify="center">
                                      <GridItem xs={12} sm={12} md={12}>
                                          <span style={{display: 'none'}}>{userRegisterForm.$('confirmPassword').error}</span>
                                          <CustomInput
                                              formControlProps={{
                                                  fullWidth: true,
                                                  className: classes.customFormControlClasses
                                              }}
                                              inputProps={{
                                                  endAdornment: (
                                                      <InputAdornment
                                                          position="end"
                                                          className={classes.inputAdornment}
                                                          onClick={this.handleToggleShowConfirmPw}
                                                      >
                                                          {this.props.authStore.userRegisterForm.$("confirmPassword").get("type") === "password" ? <RemoveRedEye className={classes.inputAdornmentIcon} /> :
                                                              <VisibilityOff className={classes.inputAdornmentIcon} />}
                                                      </InputAdornment>
                                                  ),
                                                  ...userRegisterForm.$('confirmPassword').bind()
                                              }}
                                              error={!!userRegisterForm.$('confirmPassword').error}
                                              helpText={
                                                  userRegisterForm.$('confirmPassword').error && userRegisterForm.$('confirmPassword').value !== userRegisterForm.$('password').value ? 'Passwords don\'t match' : userRegisterForm.$('confirmPassword').error || undefined
                                              }
                                              labelText='Confirm password'
                                          />
                                      </GridItem>
                                  </GridContainer>
                                  <GridContainer justify="center">
                                      <GridItem xs={12} sm={12} md={12}>
                                          <CustomInput
                                              formControlProps={{
                                                  fullWidth: true,
                                                  className: classes.customFormControlClasses
                                              }}
                                              inputProps={userRegisterForm.$('groupId').bind()}
                                              error={!!userRegisterForm.$('groupId').error}
                                              helpText={userRegisterForm.$('groupId').error || undefined}
                                              labelText='Enter your telegram groupId'
                                          />
                                      </GridItem>
                                  </GridContainer>

                                  {/*<div className={classes.center}>*/}
                                  {/*    <FormControlLabel*/}
                                  {/*        classes={{*/}
                                  {/*            root: classes.checkboxLabelControl,*/}
                                  {/*            label: classes.checkboxLabel*/}
                                  {/*        }}*/}
                                  {/*        control={*/}
                                  {/*            <Checkbox*/}
                                  {/*                tabIndex={-1}*/}
                                  {/*                onClick={userRegisterForm.$('agreeTerm').onChange}*/}
                                  {/*                checkedIcon={*/}
                                  {/*                    <Check className={classes.checkedIcon} />*/}
                                  {/*                }*/}
                                  {/*                icon={<Check className={classes.uncheckedIcon} />}*/}
                                  {/*                classes={{*/}
                                  {/*                    checked: classes.checked,*/}
                                  {/*                    root: classes.checkRoot*/}
                                  {/*                }}*/}
                                  {/*            />*/}
                                  {/*        }*/}
                                  {/*        label={*/}
                                  {/*            <span>I Accept {' '}<a href="#pablo">Terms and Conditions of Use</a>.</span>*/}
                                  {/*        }*/}
                                  {/*    />*/}
                                  {/*</div>*/}
                                  <div className={classes.center}>
                                      <Button round color="primary" size="lg" type="submit">Sign up</Button>
                                      {/*<Button round color="primary" size="lg" type="submit" disabled={!userRegisterForm.$('agreeTerm').value}>Sign up</Button>*/}
                                      {/*<div className={classes.bottomText}>Already have an account? <Link className={classes.loginLink} to="/auth/login-page">Log In</Link> </div>*/}
                                  </div>
                              </form>
                          </CardBody>
                      </Card>
                  </GridItem>
              </GridContainer>
          }
      </div>
    );
  }
}

UserRegister.propTypes = {
  // classes: PropTypes.object.isRequired
};

export default withStyles(registerPageStyle)(inject('authStore', 'userStore')(observer(UserRegister)));
