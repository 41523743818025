import React from 'react';
import { inject, observer } from 'mobx-react';
import {withRouter} from 'react-router-dom';
import withStyles from "@material-ui/core/styles/withStyles";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import CardIcon from "../../components/Card/CardIcon.jsx";
import CardHeader from "../../components/Card/CardHeader.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import {Divider, FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {cardTitle} from "../../assets/jss/material-dashboard-pro-react";
import {AccountCircle} from "@material-ui/icons";
import Wizard from "../../components/Wizard/Wizard";
import OpenOrder from "../Account/components/OpenOrder";
import TransactionHistory from "../Account/components/TransactionHistory";
import SpotBalance from "../Account/components/SpotBalance";
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import CustomInput from "../../components/CustomInput/CustomInput";
import {exchangeList, FUND_TYPE} from "../../services/utils";
import notificationService from "../../services/notificationService";
import InputAdornment from "@material-ui/core/InputAdornment";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import SecondPasswordForm from "../../stores/form/secondPassword";
import FutureOpenOrder from "../Account/components/FutureOpenOrder";
import FutureBalance from "../Account/components/FutureBalance";
import FutureTransactionHistory from "../Account/components/FutureTransactionHistory";
import FuturePosition from "../Account/components/FuturePosition";

const styles = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    inputAdornment: {
        position: "relative"
    },
    justifyContentSpaceBetween: {
        justifyContent: "space-between"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    label: {
        fontWeight: "bold",
        marginTop: "10px",
        marginBottom: "10px"
    },
    fullHeight: {
        height: '100%'
    },
    mbt10: {
        marginTop: "10px",
        marginBottom: "10px",
    },
    ml10: {
        marginLeft: "10px"
    },
    mr10: {
        marginRight: "10px"
    }
};

class AccountDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submittingSend: false,
            isEditModalShow: false,
            isDeleteModalShow: false,
        }
        this.props.userStore.reset();
        this.secondPasswordForm = new SecondPasswordForm();
    }

    componentDidMount() {
        this.props.userStore.getAccountById(this.requestId).then(res => {
        });
    }

    componentWillUnmount() {
        this.props.userStore.reset();
    }

    get requestId() {
        return this.props.match.params.id;
    };

    submitUpdateAccount = async () => {
        const { addNewAccountForm } = this.props.userStore;
        const addNewForm = addNewAccountForm.values();
        const secondPasswordForm = this.secondPasswordForm.values();

        if (!this.secondPasswordForm.isValid) return this.secondPasswordForm.showErrors(true);

        if (!addNewForm.exchange || !addNewForm.initialBalance || !addNewForm.description) {
            notificationService.error('MSG_INVALID_INPUT');
            return;
        }
        const request = {
            exchange: addNewForm.exchange.toUpperCase(),
            fundType: addNewForm.fundType,
            description: addNewForm.description,
            note: addNewForm.note,
            initialCap: addNewForm.initialBalance,
            password: secondPasswordForm.password,
        };

        this.setState({ submittingSend: true });
        const res = await this.props.userStore.createRequestUpdateAccountById(this.requestId, request);
        console.log('submitUpdateAccount res', res);
        if (res.code === 200) {
            notificationService.success('MSG_SUCCESSFUL');
        } else {
            notificationService.error(res.message);
        }
        this.setState({ submittingSend: false, isEditModalShow: false });
    };

    testFunc = () => {}

    toggleShowSecondPassword = () => {
        if (this.secondPasswordForm.$("password").get("type") === "text") {
            this.secondPasswordForm.$("password").set("type", "password");
        } else {
            this.secondPasswordForm.$("password").set("type", "text");
        }
    };

    toggleEditModal = () => {
        this.setState({ isEditModalShow: !this.state.isEditModalShow });
    };

    renderEditModal = () => {
        return (
            <SweetAlert
                title="Enter security password"
                onConfirm={this.submitUpdateAccount}
                onCancel={this.toggleEditModal}
                confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
                cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.gray}
                confirmBtnText="Edit"
                cancelBtnText="Cancel"
                showCancel
            >
                <CustomInput
                    formControlProps={{fullWidth: true, className: this.props.classes.customFormControlClasses}}
                    inputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                className={this.props.classes.inputAdornment}
                                onClick={this.toggleShowSecondPassword}
                            >
                                {this.secondPasswordForm.$("password").get("type") === "password" ? <RemoveRedEye className={this.props.classes.inputAdornmentIcon} /> :
                                    <VisibilityOff className={this.props.classes.inputAdornmentIcon} />}
                            </InputAdornment>
                        ),
                        ...this.secondPasswordForm.$('password').bind()
                    }}
                    error={!!this.secondPasswordForm.$('password').error}
                    helpText={this.secondPasswordForm.$('password').error || undefined}
                    labelText='Please enter security password to process'
                />
            </SweetAlert>
        )
    };

    toggleDeleteModal = () => {
        this.setState({ isDeleteModalShow: !this.state.isDeleteModalShow });
    };

    renderDeleteModal = () => {
        return (
            <SweetAlert
                warning
                title=""
                onConfirm={this.handleDeleteAccount}
                onCancel={this.toggleDeleteModal}
                confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
                cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.gray}
                confirmBtnText="Yes"
                cancelBtnText="No"
                showCancel
            >
                <p style={styles.titleModal}>
                    Are you sure that you want to{" "}
                    <span style={{
                        color: "#f44336",
                        textDecoration: "underline"
                    }}
                    >
                        delete
                    </span>{" "}
                    this account?
                </p>

                <CustomInput
                    formControlProps={{fullWidth: true, className: this.props.classes.customFormControlClasses}}
                    inputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                className={this.props.classes.inputAdornment}
                                onClick={this.toggleShowSecondPassword}
                            >
                                {this.secondPasswordForm.$("password").get("type") === "password" ? <RemoveRedEye className={this.props.classes.inputAdornmentIcon} /> :
                                    <VisibilityOff className={this.props.classes.inputAdornmentIcon} />}
                            </InputAdornment>
                        ),
                        ...this.secondPasswordForm.$('password').bind()
                    }}
                    error={!!this.secondPasswordForm.$('password').error}
                    helpText={this.secondPasswordForm.$('password').error || undefined}
                    labelText='Please enter security password to process'
                />
            </SweetAlert>
        )
    };

    handleDeleteAccount = async () => {
        if (!this.secondPasswordForm.isValid) return this.secondPasswordForm.showErrors(true);

        this.setState({ submittingSend: true });
        const request = {
            password: this.secondPasswordForm.values().password,
        };
        const res = await this.props.userStore.createRequestRemoveAccountById(this.requestId, request);
        console.log('handleDeleteAccount res', res);
        if (res.code === 200) {
            notificationService.success('MSG_DEL_SUCCESSFUL');
            this.props.history.push('/dashboard');
        } else {
            notificationService.error(res.message);
        }

        this.setState({ submittingSend: false, isDeleteModalShow: false });
    }

    render() {
        const { classes } = this.props;
        const { addNewAccountForm } = this.props.userStore;

        return (
            <GridContainer justify="center">
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <AccountCircle />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Details</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer className={classes.container}>
                                <GridItem xs={10}>
                                    <GridItem xs={6}>
                                        <GridContainer>
                                            <GridItem xs={12}>
                                                <FormControl fullWidth className={classes.selectFormControl}>
                                                    <InputLabel htmlFor="simple-select"
                                                                className={classes.selectLabel}
                                                                style={{fontWeight: 400, fontSize: '1rem'}}>
                                                        Fund
                                                    </InputLabel>
                                                    <Select MenuProps={{className: classes.selectMenu}}
                                                            classes={{select: classes.select}}
                                                            inputProps={{name: "simpleSelect", id: "simple-select"}}
                                                            value={addNewAccountForm.$('fundType').value}
                                                            onChange={addNewAccountForm.$('fundType').onChange}>
                                                        {FUND_TYPE.map(item =>
                                                            <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                                                                      value={item.code}
                                                                      key={item.code}
                                                            >
                                                                {item.name}
                                                            </MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                            <GridItem xs={12}>
                                                <FormControl fullWidth className={classes.selectFormControl}>
                                                    <InputLabel htmlFor="simple-select"
                                                                className={classes.selectLabel}
                                                                style={{fontWeight: 400, fontSize: '1rem'}}>
                                                        Exchange
                                                    </InputLabel>
                                                    <Select MenuProps={{className: classes.selectMenu}}
                                                            classes={{select: classes.select}}
                                                            inputProps={{name: "simpleSelect", id: "simple-select"}}
                                                            value={addNewAccountForm.$('exchange').value}
                                                            onChange={addNewAccountForm.$('exchange').onChange}>
                                                        {exchangeList.map(item =>
                                                            <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                                                                      value={item.code}
                                                                      key={item.code}
                                                            >
                                                                {item.name}
                                                            </MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>

                                            </GridItem>
                                            <GridItem xs={12}>
                                                <CustomInput labelText='Description'
                                                             error={!!addNewAccountForm.$('description').error}
                                                             helpText={addNewAccountForm.$('description').error || undefined}
                                                             formControlProps={{fullWidth: true}}
                                                             inputProps={addNewAccountForm.$('description').bind()}
                                                />

                                            </GridItem>
                                            <GridItem xs={12}>
                                                <CustomInput labelText='Initial Balance'
                                                             error={!!addNewAccountForm.$('initialBalance').error}
                                                             helpText={addNewAccountForm.$('initialBalance').error || undefined}
                                                             formControlProps={{fullWidth: true}}
                                                             inputProps={addNewAccountForm.$('initialBalance').bind()}
                                                />
                                            </GridItem>
                                            <GridItem xs={12}>
                                                <CustomInput labelText='Note'
                                                             error={!!addNewAccountForm.$('note').error}
                                                             helpText={addNewAccountForm.$('note').error || undefined}
                                                             formControlProps={{fullWidth: true}}
                                                             inputProps={{
                                                                 multiline: true,
                                                                 rows: 2,
                                                                 ...addNewAccountForm.$('note').bind()
                                                             }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12}>
                                                <CustomInput labelText='Api Key'
                                                             error={!!addNewAccountForm.$('addressKey').error}
                                                             helpText={addNewAccountForm.$('addressKey').error || undefined}
                                                             formControlProps={{fullWidth: true}}
                                                             inputProps={addNewAccountForm.$('addressKey').bind()}
                                                />

                                            </GridItem>
                                            <GridItem xs={12}>
                                                <CustomInput labelText='Secret Key'
                                                             error={!!addNewAccountForm.$('secretKey').error}
                                                             helpText={addNewAccountForm.$('secretKey').error || undefined}
                                                             formControlProps={{fullWidth: true}}
                                                             inputProps={addNewAccountForm.$('secretKey').bind()}
                                                />

                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                </GridItem>
                                <GridItem xs={2}>
                                    {/*<GridContainer className={classes.fullHeight} direction="column" justify="space-between">*/}
                                    {/*</GridContainer>*/}
                                    <Button fullWidth color="info" onClick={this.toggleEditModal} loading={this.props.submittingSend} disabled={this.props.submittingSend}>
                                        Edit
                                    </Button>
                                    <Button fullWidth color="danger" onClick={this.toggleDeleteModal} loading={this.props.submittingSend} disabled={this.props.submittingSend}>
                                        Remove
                                    </Button>
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12}>
                                    <Divider className={classes.mbt10} />
                                </GridItem>
                                <GridItem xs={12}>
                                    <Wizard
                                        color={'primary'}
                                        validate
                                        steps={[
                                            { stepName: "Spot Balance", stepComponent: SpotBalance, stepId: "spotBalance" },
                                            { stepName: "Open Orders", stepComponent: OpenOrder, stepId: "openOrder" },
                                            { stepName: "Trade History", stepComponent: TransactionHistory, stepId: "transactionHistory" },
                                            { stepName: "Future Balance", stepComponent: FutureBalance, stepId: "futureBalance" },
                                            { stepName: "Future Open Position", stepComponent: FuturePosition, stepId: "futurePosition" },
                                            { stepName: "Future Open Order", stepComponent: FutureOpenOrder, stepId: "futureOpenOrder" },
                                            { stepName: "Future Trade History", stepComponent: FutureTransactionHistory, stepId: "futureTradeHistory" },
                                        ]}
                                        title="Add your new account"
                                        subtitle=""
                                        finishButtonClick={this.testFunc}
                                        finishButtonText="Done"
                                        previousButtonText="Back"
                                        submitting={this.state.submitting}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
                {this.state.isDeleteModalShow && this.renderDeleteModal()}
                {this.state.isEditModalShow && this.renderEditModal()}
            </GridContainer>
        )
    }
}

export default withStyles({ ...sweetAlertStyle, ...extendedFormsStyle, ...styles })(inject('dashboardStore', 'userStore')(withRouter(observer(AccountDetails))));
