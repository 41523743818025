import { Form } from "mobx-react-form";
import plugins from "./plugins";

export default class SearchFieldForm extends Form {
    plugins() {
        return plugins;
    }

    setup() {
        return {
            fields: [
                {
                    name: "search",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "pair",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
            ]
        }
    }
}
