import { decorate, observable, action } from "mobx";
import commonStore from "./commonStore";
import {
    changePassword,
    getProfile,
    verifyAccount,
    addNewAccount,
    updateTelegramSetting,
    getListUserSubscribeTelegram,
    updateStatusTelegramUser,
    getAccountById,
    getOpenOrdersByAccountId,
    updateAccountById,
    removeAccountById,
    getBalancesByAccountId,
    getTradeHistoryByAccountId,
    getSymbolSupport,
    createUserDataStream,
    getFutureBalancesByAccId,
    getFutureOpenOrdersByAccId, getFutureTradeHistoryByAccId, getOkxSymbolSupport,
} from "../services/domainServices/userDomain.service";
import axios from "axios";
import Cookie from "js-cookie";
import AccountSettingsForm from "./form/accountSettings";
import AddNewAccountForm from "./form/addNewAccount";
import * as _ from 'lodash';
import notificationService from "../services/notificationService";
import dashboardStore from "./dashboardStore";
import config from "../config"
import {dynamicSort, dynamicSortCustom, updateSourceExchange} from "../services/utils";

const initialUserListPagination = {
    page: 1,
    items: [],
    size: 20,
    totalPage: 0,
    totalItem: 0
};

const initialPagination = {
    size: 20,
    page: 0,
    accName: ""
};

const initialPaginationFuturePosition = {
    size: 50,
    page: 0,
    params: "",
    sort: "symbol",
    order: "desc",
};

class UserStore {
    currentUser;
    isNewRegister = false;
    symbolSupportList = [];
    symbolSupportListOkxSpot = [
        {
            code: '',
            name: 'ALL',
        },
    ];
    symbolSupportListOkxFuture = [
        {
            code: '',
            name: 'ALL',
        },
    ];
    isLoading = false;
    userListPagination = _.cloneDeep(initialUserListPagination);
    pagination = _.cloneDeep(initialPagination);
    paginationFuturePosition = _.cloneDeep(initialPaginationFuturePosition);
    paginationFutureOpenOrder = _.cloneDeep(initialPaginationFuturePosition);
    paginationFutureTransactionHistory = _.cloneDeep(initialPaginationFuturePosition);
    paginationOpenOrder = _.cloneDeep(initialPaginationFuturePosition);
    paginationSpotBalance = _.cloneDeep(initialPaginationFuturePosition);
    paginationFutureBalance = _.cloneDeep(initialPaginationFuturePosition);
    paginationTransactionHistory = _.cloneDeep(initialPaginationFuturePosition);

    isOkxExchange = false;
    isBybitExchange = false;

    listenKey = "";
    isWsSubscribed = false;

    isOpenOrdersLoading = false;
    accountAllOrderList = [];

    isBalanceLoading = false;
    accountBalanceList = [];

    isTradeHistoryLoading = false;
    accountTradeHistoryList = [];

    isFutureTradeHistoryLoading = false;
    accountTradeHistoryListFuture = [];

    isFutureBalanceLoading = false;
    accountBalanceListFuture = [];

    isFutureOpenOrderLoading = false;
    accountOpenOrderListFuture = [];

    heartBeatId;
    keepAliveIntervalId;

    sourceExchange = 0;

    constructor() {
        this.accountSettingsForm = new AccountSettingsForm();
        this.addNewAccountForm = new AddNewAccountForm();
    }

    toggleShowCurrentPassword = () => {
        if (
            this.accountSettingsForm.$("current_password").get("type") ===
            "text"
        ) {
            this.accountSettingsForm
                .$("current_password")
                .set("type", "password");
        } else {
            this.accountSettingsForm.$("current_password").set("type", "text");
        }
    };

    toggleShowNewPassword = () => {
        if (this.accountSettingsForm.$("new_password").get("type") === "text") {
            this.accountSettingsForm.$("new_password").set("type", "password");
        } else {
            this.accountSettingsForm.$("new_password").set("type", "text");
        }
    };

    toggleShowRetypeNewPassword = () => {
        if (
            this.accountSettingsForm.$("retype_new_password").get("type") ===
            "text"
        ) {
            this.accountSettingsForm
                .$("retype_new_password")
                .set("type", "password");
        } else {
            this.accountSettingsForm
                .$("retype_new_password")
                .set("type", "text");
        }
    };

    changePassword(data) {
        return changePassword(data);
    }

    changeSortOfPaginationFutureOpenPosition = (sort, isNumber) => {
        let order = "desc";
        if (this.paginationFuturePosition.sort === sort && this.paginationFuturePosition.order === "desc") {
            order = "asc";
        }
        this.paginationFuturePosition.order = order;
        this.paginationFuturePosition.sort = sort;
        console.log('sort', sort, 'order', order)

        if (!this.accountBalanceListFuture || this.accountBalanceListFuture.positions.length === 0) return;

        this.accountBalanceListFuture.positions = this.accountBalanceListFuture.positions.slice().sort(dynamicSortCustom(sort, order, isNumber));
    }

    changeSortOfPaginationFutureOpenOrder = (sort, isNumber) => {
        let order = "desc";
        if (this.paginationFutureOpenOrder.sort === sort && this.paginationFutureOpenOrder.order === "desc") {
            order = "asc";
        }
        this.paginationFutureOpenOrder.order = order;
        this.paginationFutureOpenOrder.sort = sort;
        console.log('sort', sort, 'order', order)

        if (!this.accountOpenOrderListFuture || this.accountOpenOrderListFuture.length === 0) return;

        this.accountOpenOrderListFuture = this.accountOpenOrderListFuture.slice().sort(dynamicSortCustom(sort, order, isNumber));
    }

    changeSortOfPaginationSpotBalance = (sort, isNumber) => {
        let order = "desc";
        if (this.paginationSpotBalance.sort === sort && this.paginationSpotBalance.order === "desc") {
            order = "asc";
        }
        this.paginationSpotBalance.order = order;
        this.paginationSpotBalance.sort = sort;
        console.log('sort', sort, 'order', order)

        if (!this.accountBalanceList || this.accountBalanceList.length === 0) return;

        this.accountBalanceList = this.accountBalanceList.slice().sort(dynamicSortCustom(sort, order, isNumber));
    }

    changeSortOfPaginationFutureBalance = (sort, isNumber) => {
        let order = "desc";
        if (this.paginationFutureBalance.sort === sort && this.paginationFutureBalance.order === "desc") {
            order = "asc";
        }
        this.paginationFutureBalance.order = order;
        this.paginationFutureBalance.sort = sort;
        console.log('sort', sort, 'order', order)

        if (!this.accountBalanceListFuture || this.accountBalanceListFuture.assets.length === 0) return;

        this.accountBalanceListFuture.assets = this.accountBalanceListFuture.assets.slice().sort(dynamicSortCustom(sort, order, isNumber));
    }

    changeSortOfPaginationTransactionHistory = (sort, isNumber) => {
        let order = "desc";
        if (this.paginationTransactionHistory.sort === sort && this.paginationTransactionHistory.order === "desc") {
            order = "asc";
        }
        this.paginationTransactionHistory.order = order;
        this.paginationTransactionHistory.sort = sort;
        console.log('sort', sort, 'order', order)

        if (!this.accountTradeHistoryList || this.accountTradeHistoryList.length === 0) return;

        this.accountTradeHistoryList = this.accountTradeHistoryList.slice().sort(dynamicSortCustom(sort, order, isNumber));
    }

    changeSortOfPaginationFutureTransactionHistory = (sort, isNumber) => {
        let order = "desc";
        if (this.paginationFutureTransactionHistory.sort === sort && this.paginationFutureTransactionHistory.order === "desc") {
            order = "asc";
        }
        this.paginationFutureTransactionHistory.order = order;
        this.paginationFutureTransactionHistory.sort = sort;
        console.log('sort', sort, 'order', order)

        if (!this.accountTradeHistoryListFuture || this.accountTradeHistoryListFuture.length === 0) return;

        this.accountTradeHistoryListFuture = this.accountTradeHistoryListFuture.slice().sort(dynamicSortCustom(sort, order, isNumber));
    }

    changeSortOfPaginationOpenOrder = (sort, isNumber) => {
        let order = "desc";
        if (this.paginationOpenOrder.sort === sort && this.paginationOpenOrder.order === "desc") {
            order = "asc";
        }
        this.paginationOpenOrder.order = order;
        this.paginationOpenOrder.sort = sort;
        console.log('sort', sort, 'order', order)

        if (!this.accountAllOrderList || this.accountAllOrderList.length === 0) return;

        this.accountAllOrderList = this.accountAllOrderList.slice().sort(dynamicSortCustom(sort, order, isNumber));
    }

    createRequestVerifyAccount(request) {
        return verifyAccount(request);
    }

    createRequestAddNewAccount(request) {
        return addNewAccount(request);
    }

    createRequestUpdateTelegramSetting(request) {
        return updateTelegramSetting(request);
    }

    createRequestChangeStatusTelegramUser(id) {
        return updateStatusTelegramUser(id);
    }

    changePageOfPagination = page => {
        this.pagination.page = page;
    };

    changePageSizeOfPagination = pageSize => {
        this.pagination.size = pageSize;
    };

    getListUserSubTelegram = (page, size) => {
        this.isLoading = true;
        return getListUserSubscribeTelegram(page, size).then(
            action(res => {
                console.log('getListUserSubTelegram', res);
                this.isLoading = false;
                this.userListPagination = res;
            })
        );
    };

    getAllOpenOrders = id => {
        this.isOpenOrdersLoading = true;
        return getOpenOrdersByAccountId(id).then(
            action(res => {
                console.log('getOpenOrdersByAccountId res', res);
                this.isOpenOrdersLoading = false;
                if (res.code !== 200 || !res.data) {
                    notificationService.error(res.message);
                    return;
                }

                this.accountAllOrderList = res.data;

                if (this.isOkxExchange) {
                    this.accountAllOrderList = res.data.filter(item => item.instType === 'SPOT');
                    this.accountOpenOrderListFuture = res.data.filter(item => item.instType === 'FUTURES' || item.instType === 'SWAP');
                }
            })
        )
    }

    getBalances = id => {
        this.isBalanceLoading = true;
        return getBalancesByAccountId(id).then(
            action(res => {
                console.log('getBalancesByAccountId res', res);
                this.isBalanceLoading = false;
                if (res.code !== 200 || !res.data) {
                    notificationService.error(res.message);
                    return;
                }

                this.accountBalanceList = res.data.balances;
            })
        )
    }

    getTradeHistory = (id, symbol) => {
        this.isTradeHistoryLoading = true;
        return getTradeHistoryByAccountId(id, symbol).then(
            action(res => {
                console.log('getTradeHistoryByAccountId res', res);
                this.isTradeHistoryLoading = false;
                if (res.code !== 200 || !res.data) {
                    notificationService.error(res.message);
                    return;
                }

                this.accountTradeHistoryList = res.data.sort(dynamicSort( 'updateTime', 'desc'));
            })
        )
    }

    getBalancesFuture = id => {
        this.isFutureBalanceLoading = true;
        return getFutureBalancesByAccId(id).then(
            action(res => {
                console.log('getFutureBalancesByAccId res', res);
                this.isFutureBalanceLoading = false;
                if (res.code !== 200 || !res.data) {
                    notificationService.error(res.message);
                    return;
                }

                this.accountBalanceListFuture = res.data;
                if (res.data.assets) {
                    this.accountBalanceListFuture.assets = res.data.assets.map(item => {
                        return {
                            ...item,
                            balanceCalculated: item.marginBalance*1 + item.unrealizedProfit*1
                        }
                    })
                }

                if (this.isOkxExchange && res.data.assets) {
                    this.accountBalanceList = res.data.assets.map(item => {
                        return {
                            asset: item.asset,
                            free: item.availableBalance,
                            locked: item.walletBalance*1 - item.availableBalance*1
                        }
                    });
                }
            })
        )
    }

    getTradeHistoryFuture = (id, symbol) => {
        this.isFutureTradeHistoryLoading = true;
        return getFutureTradeHistoryByAccId(id, symbol).then(
            action(res => {
                console.log('getFutureTradeHistoryByAccountIdApi res', res);
                this.isFutureTradeHistoryLoading = false;
                if (res.code !== 200 || !res.data) {
                    notificationService.error(res.message);
                    return;
                }

                this.accountTradeHistoryListFuture = res.data.sort(dynamicSort( 'updateTime', 'desc'));
            })
        )
    }

    getAllOpenOrdersFuture = (id, pair) => {
        this.isFutureOpenOrderLoading = true;
        return getFutureOpenOrdersByAccId(id, pair).then(
            action(res => {
                console.log('getFutureOpenOrdersByAccId res', res);
                this.isFutureOpenOrderLoading = false;
                if (res.code !== 200 || !res.data) {
                    notificationService.error(res.message);
                    return;
                }

                this.accountOpenOrderListFuture = res.data;
            })
        )
    }

    reset() {
        this.accountAllOrderList = [];
        this.accountBalanceList = [];
        this.accountTradeHistoryList = [];
        this.accountTradeHistoryListFuture = [];
        this.accountBalanceListFuture = [];
        this.accountOpenOrderListFuture = [];
        this.addNewAccountForm = new AddNewAccountForm();
    }

    createRequestUpdateAccountById = (id, request) => {
        return updateAccountById(id, request);
    }

    createRequestRemoveAccountById = (id, data) => {
        return removeAccountById(id, data);
    }

    getAccountById = id => {
        return getAccountById(id).then(
            action(res => {
                console.log('getAccountById getAccountById ' + id, res);
                if (res.code === 200 && res.data) {
                    this.addNewAccountForm.set({exchange: res.data.exchange});
                    this.addNewAccountForm.set({initialBalance: res.data.initialCap});
                    this.addNewAccountForm.set({note: res.data.note});
                    this.addNewAccountForm.set({description: res.data.description});
                    this.addNewAccountForm.set({addressKey: res.data.apiKey});
                    this.addNewAccountForm.$('addressKey').set('disabled', true);
                    this.addNewAccountForm.set({secretKey: res.data.secretKey});
                    this.addNewAccountForm.$('secretKey').set('disabled', true);
                    this.addNewAccountForm.set({fundType: res.data.fundType + ''});

                    this.sourceExchange = updateSourceExchange(res.data.exchange);
                    if (res.data.exchange === 'OKX') {
                        this.isOkxExchange = true;
                    } else if (res.data.exchange === 'BYBIT') {
                        this.isBybitExchange = true;
                    } else {
                        this.isOkxExchange = false;
                        this.isBybitExchange = false;
                    }
                }
            })
        );
    };

    setUserToken(rawData, rememberMe) {
        let role = 'USER';
        if (rawData && rawData.permissions && rawData.permissions[0] && rawData.permissions[0].code) {
            role = rawData.permissions[0].code;
        }
        console.log('setUserToken data', rawData);
        console.log('role', role);

        const data = {
            accessToken: rawData.sessionToken,
            role: role,
        }
        // if (data.role === "admin") {
        //     // this.logout();
        //     Cookie.set("__user_credential_console", JSON.stringify(data), {
        //         expires: 14
        //     });
        //     // window.location.href = "/admin";
        // } else {
            Cookie.set("__user_credential", JSON.stringify(data), {
                expires: rememberMe ? 14 : 1
            });
        // }
    }

    getSymbolExchangeInfo = () => {
        getSymbolSupport().then(action(res => {
            // console.log('getSymbolSupport res', res)
            if (!res.data || !res.data.symbols) return;

            for (let symbolFilter of res.data.symbols) {
                if (!symbolFilter.symbol.includes('USDT')) continue;

                this.symbolSupportList.push({
                    code: symbolFilter.symbol,
                    name: symbolFilter.symbol,
                })
            }
        }));
    }

    getOkxSymbolExchangeInfo = (instType) => {
        getOkxSymbolSupport(instType).then(action(res => {
            // console.log('getOkxSymbolExchangeInfo res', res)
            if (!res || !res.data) return;

            for (let symbolInfo of res.data.data) {
                if (!symbolInfo.instId.includes('USDT')) continue;

                if (instType === 'SPOT') {
                    this.symbolSupportListOkxSpot.push({
                        code: symbolInfo.instId,
                        name: symbolInfo.instId,
                    })
                } else if (instType === 'FUTURES' || instType === 'SWAP') {
                    this.symbolSupportListOkxFuture.push({
                        code: symbolInfo.instId,
                        name: symbolInfo.instId,
                    })
                }
            }
        }))
    }

    async getCurrentUser() {
        const userCredential = Cookie.getJSON("__user_credential");
        console.log('userCredential', userCredential);
        if (userCredential) {
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${userCredential.accessToken}`;
            return getProfile().then(action(data => {
                console.log('getCurrentUser getProfile data', data);
                this.currentUser = data.data;
                this.currentUser.role = userCredential.role ? userCredential.role : 'USER';

                createUserDataStream().then(action(res => {
                    // console.log('createUserDataStream res', res);
                    this.listenKey = res.listenKey;
                    this.initWs(res.listenKey);
                }));
            })).catch((e) => {
                console.log('getCurrentUser getProfile', e);
                this.logout();
            }).finally(() => commonStore.setAppLoaded());
        } else {
            commonStore.setAppLoaded();
        }
    }

    initWs = (listenKey) => {
        const socket = new WebSocket(config.WS_API);
        socket.onopen = () => {
            notificationService.success('Websocket is connected');
            console.log('ws open ok', new Date());

            const subscribeMessage = {
                method: "SUBSCRIBE",
                id: 1,
                params: [listenKey]
            };

            socket.send(JSON.stringify(subscribeMessage));

            this.keepAliveIntervalId = setInterval(() => {
                // ping interval 15min
                console.log('callKeepAlive interval 15min')
                dashboardStore.callKeepAlive(listenKey);
            }, 300000)

            this.heartBeatId = setInterval(() => {
                // console.log('ping interval 10s')
                socket.send("ping");
            }, 60000);
        }
        socket.onclose = async (e) => {
            notificationService.warning('Websocket is closed. Attempt to reconnect after 3s');
            console.log('ws closed at', new Date(), 'attempt to reconnect after 2s', e);

            clearInterval(this.heartBeatId);
            clearInterval(this.keepAliveIntervalId);
            // reconnect
            const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
            await delay(3000);
            this.initWs(listenKey);
        }
        socket.onerror = (e) => console.error('ws error', new Date(), e);

        socket.onmessage = ({data}) => {
            // console.log('incoming ws message');
            data = JSON.parse(data);
            // console.log('data', data);

            if (data.id !== null && !data.result) dashboardStore.accountUpdateEvent(data);
        };
    }

    setIsNewRegister = value => {
        this.isNewRegister = value;
    };

    logout() {
        this.currentUser = null;
        this.reset();
        this.addNewAccountForm.reset();
        this.accountSettingsForm.reset();
        Cookie.remove("__user_credential");
    }
}

decorate(UserStore, {
    userListPagination: observable,
    pagination: observable,
    paginationFuturePosition: observable,
    paginationFutureOpenOrder: observable,
    paginationFutureTransactionHistory: observable,
    paginationOpenOrder: observable,
    paginationSpotBalance: observable,
    paginationFutureBalance: observable,
    paginationTransactionHistory: observable,
    currentUser: observable,
    isNewRegister: observable,
    isLoading: observable,
    sourceExchange: observable,
    isLoadingManualTrade: observable,
    isWsSubscribed: observable,
    symbolSupportList: observable,
    symbolSupportListOkxSpot: observable,
    symbolSupportListOkxFuture: observable,
    accountAllOrderList: observable,
    accountBalanceList: observable,
    accountTradeHistoryList: observable,
    isFutureTradeHistoryLoading: observable,
    accountTradeHistoryListFuture: observable,
    isFutureBalanceLoading: observable,
    accountBalanceListFuture: observable,
    isFutureOpenOrderLoading: observable,
    accountOpenOrderListFuture: observable,
    isOkxExchange: observable,
    isBybitExchange: observable,
    listenKey: observable,
    setUserToken: action,
    getCurrentUser: action,
    getSymbolExchangeInfo: action,
    createRequestVerifyAccount: action,
    createRequestConfirmAccount: action,
    createRequestUpdateTelegramSetting: action,
    createRequestChangeStatusTelegramUser: action,
    createRequestUpdateAccountById: action,
    changeEstimateReceive: action,
    changeSortOfPaginationFutureOpenPosition: action,
    changeSortOfPaginationFutureOpenOrder: action,
    changeSortOfPaginationFutureTransactionHistory: action,
    changeSortOfPaginationOpenOrder: action,
    changeSortOfPaginationSpotBalance: action,
    changeSortOfPaginationFutureBalance: action,
    changeSortOfPaginationTransactionHistory: action,
    logout: action,
    setIsNewRegister: action
});

export default new UserStore();
