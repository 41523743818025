import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

class UnauthorizedRoute extends React.Component {
  render() {
    const { userStore, ...restProps } = this.props;
    if (userStore.currentUser) return <Redirect to="/" />;
    return <Route {...restProps} />;
  }
}

export default inject('userStore')(observer(UnauthorizedRoute));
