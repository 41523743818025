import React from "react";
import ReactDOM from "react-dom";
import { createHashHistory } from "history";
import { Router, Switch } from "react-router-dom";

import AuthLayout from "./layouts/Auth.jsx";
import DashboardLayout from "./layouts/Dashboard.jsx";

import "./App.css";
import "react-datetime/css/react-datetime.css";
import UnauthorizedRoute from "./components/Route/UnauthorizedRoute";
import AuthorizedRoute from "./components/Route/AuthorizedRoute";
import stores from "./stores";
import { Provider, inject, observer } from "mobx-react";
import Snackbar from "./components/Snackbar/Snackbar";
import Loading from "./components/Loading/Loading";

window._____APP_STATE_____ = stores;

const hist = createHashHistory();

class App extends React.Component {
    componentDidMount() {
        this.props.userStore.getSymbolExchangeInfo();
        this.props.userStore.getOkxSymbolExchangeInfo('SPOT');
        this.props.userStore.getOkxSymbolExchangeInfo('FUTURES');
        this.props.userStore.getOkxSymbolExchangeInfo('SWAP');
        this.props.userStore.getCurrentUser();
    }

    render() {
        // const { currentUser } = this.props.userStore;
        // console.log('currentUser', currentUser);
        if (this.props.commonStore.appLoaded) {
            const currentNotify = this.props.notificationStore.current;
            return (
                <Router history={hist}>
                    <div>
                        {currentNotify && (
                            <Snackbar
                                place="tr"
                                color={currentNotify.color}
                                message={currentNotify.message}
                                open={currentNotify.display}
                                icon={currentNotify.icon}
                                closeNotification={
                                    this.props.notificationStore.process
                                }
                                onClose={this.props.notificationStore.hide}
                                onExited={this.props.notificationStore.process}
                                close
                            />
                        )}
                        <Switch>
                            <UnauthorizedRoute
                                path="/auth"
                                component={AuthLayout}
                            />
                            <AuthorizedRoute
                                    path="/"
                                    component={DashboardLayout}
                                />
                            {/* {currentUser &&
                            (
                                <AuthorizedRoute
                                    path="/"
                                    component={DashboardLayout}
                                />
                            )} */}
                        </Switch>
                    </div>
                </Router>
            );
        }
        return <Loading />;
    }
}

const InjectedApp = inject("notificationStore", "userStore", "commonStore")(
  observer(App)
);

ReactDOM.render(
  <Provider {...stores}>
      <InjectedApp />
  </Provider>,
  document.getElementById("root")
);
