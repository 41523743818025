import { Form } from "mobx-react-form";
import plugins from "./plugins";

export default class ForgotPasswordForm extends Form {
    plugins() {
        return plugins;
    }

    setup() {
        return {
            fields: [
                {
                    name: "username",
                    placeholder: "Enter email",
                    rules: "required",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                }
            ]
        };
    }
}
