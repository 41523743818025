import userStore from './userStore';
import authStore from './authStore';
import commonStore from './commonStore';
import notificationStore from './notificationStore';
import dashboardStore from "./dashboardStore";
import manageUserStore from './manageUserStore';

export default {
  commonStore,
  userStore,
  notificationStore,
  dashboardStore,
  authStore,
  manageUserStore,
};
