import React from 'react';
import { withRouter } from 'react-router-dom';

import ArrowBack from "@material-ui/icons/ArrowBack";
import { withStyles } from '@material-ui/core';

const styles = {
    button: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        border: 'none',
        background: 'none',
        fontSize: '14px',
        color: '#F8981F',
        padding: 0,
        '&:focus, &:hover': {
            color: '#bcbb47'
        }
    }
}

class BackPreviousPageButton extends React.Component {
    backToPreviousPage = () => {
        this.props.history.goBack();
    }
    render() {
        const { classes } = this.props;
        return <button className={classes.button} onClick={this.backToPreviousPage}>
            <ArrowBack /> Back
        </button>
    }
}

export default withStyles(styles)(withRouter(BackPreviousPageButton));