import { decorate, observable, action } from "mobx";

class CommonStore {
    appLoaded = false;

    constructor() {

    }

    setAppLoaded() {
        this.appLoaded = true;
    }

    setAppLoading() {
        this.appLoaded = false;
    }
}

decorate(CommonStore, {
    appLoaded: observable,
    setAppLoaded: action,
    setAppLoading: action,
});

export default new CommonStore();
