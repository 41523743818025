import React from 'react';
import {withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import Button from "../../components/CustomButtons/Button";
import CardBody from "../../components/Card/CardBody";
import Loading from "../../components/Loading/Loading";
import {
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead, TablePagination,
    TableRow,
    Tooltip
} from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import {cardTitle} from "../../assets/jss/material-dashboard-pro-react";
import {LibraryBooks} from "@material-ui/icons";
import CustomInput from "../../components/CustomInput/CustomInput";
import {reduceMaskText, upperFirstLetter} from "../../services/utils";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    header: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    newBtn: {
        marginTop: '15px',
        marginLeft: 'auto'
    },
    mb20: {
        marginBottom: '20px'
    },
    status: {
        borderRadius: '3px',
        textAlign: 'center',
        padding: '5px 10px',
        color: 'white'
    },
    sentStatus: {
        backgroundColor: '#00acc1'
    },
    closedStatus: {
        backgroundColor: 'gray'
    },
    expiredStatus: {
        backgroundColor: 'red'
    },
    dflex: {
        display: 'flex',
    },
    mr5: {
        marginRight: '5px'
    },
    tableCell: {
        fontSize: '1rem',
        padding: '4px 20px'
    }
};

const rows = [
    { id: 'id', numeric: false, disablePadding: false, label: 'Id', disableSort: true },
    { id: 'description', numeric: false, disablePadding: false, label: 'Description', disableSort: false },
    { id: 'account', numeric: false, disablePadding: false, label: 'Account', disableSort: false },
    { id: 'address', numeric: false, disablePadding: false, label: 'Address', disableSort: true },
    { id: 'action', numeric: false, disablePadding: false, label: 'Actions', disableSort: true },
];

const searchFields = [
    {
        code: 'apiKey',
        name: 'Address'
    },
    {
        code: 'description',
        name: 'Description'
    },
]

class ManageApiKeys extends React.Component {

    componentDidMount() {
        if (this.isAdminPermission === true) this.getAccountListAdmin();
    }

    getAccountListAdmin = () => {
        if (this.isAdminPermission === false) return;

        const { getAccountsList, pagination, searchFieldForm } = this.props.dashboardStore;
        // const { page, size, search } = pagination;
        const { page, size, params } = pagination;
        const paramRequest = params ? searchFieldForm.values().search + '=' + params : '';
        console.log('getAccountList search', paramRequest);
        getAccountsList(paramRequest, page, size);
    }

    get isAdminPermission() {
        const { currentUser } = this.props.userStore;
        console.log('isAdmin manageApiKey', currentUser.role !== 'USER');
        if (currentUser.role !== 'USER') return true;
        return false;
    }

    handleChangePage = (e, page) => {
        this.props.dashboardStore.changePageOfPagination(page);
        this.getAccountListAdmin();
    }

    handleChangePageSize = (e) => {
        this.props.dashboardStore.changePageSizeOfPagination(e.target.value);
        this.getAccountListAdmin();
    }

    setCurrentAccountManager = (accId) => {
        this.props.history.push(`/manage-api/${accId}`);
    }

    onSearchChange = (event) => {
        this.props.dashboardStore.changeAccNamePagination(event.target.value);
    }

    render() {
        const { classes } = this.props;
        const { accountsPagination, pagination, isLoading, searchFieldForm } = this.props.dashboardStore;

        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon className={classes.header}>
                            <CardIcon color="primary">
                                <LibraryBooks />
                            </CardIcon>
                            <h3 className={classes.cardIconTitle}>Manage Api Key</h3>
                            <div style={{marginLeft: 'auto', marginTop: '15px'}}>
                                {/*<FormControl className={classes.selectFormControl}>*/}
                                <Select MenuProps={{className: classes.selectMenu}}
                                        classes={{select: classes.select}}
                                        inputProps={{name: "simpleSelect", id: "simple-select"}}
                                        value={searchFieldForm.$('search').value}
                                        onChange={searchFieldForm.$('search').onChange}
                                        style={{width: '120px'}}
                                >
                                    {searchFields.map(item =>
                                        <MenuItem classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                                  value={item.code}
                                                  key={item.code}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    )}
                                </Select>
                                <CustomInput
                                    labelText='Search'
                                    formControlProps={{
                                        fullWidth: false
                                    }}
                                    inputProps={{
                                        onChange: this.onSearchChange,
                                        onKeyUp: (e) => e.key === 'Enter' && this.getAccountListAdmin() && this.isAdminPermission
                                    }}
                                />
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div style={{ overflow: 'auto' }}>
                                {isLoading ? <Loading /> : <Table aria-labelledby="tableTitle" padding="default">
                                    <TableHead>
                                        <TableRow>
                                            {rows.map(
                                                row => (
                                                    <TableCell key={row.id}
                                                               className={classes.tableCell}
                                                               align={row.numeric ? 'right' : 'left'}
                                                               padding={row.disablePadding ? 'none' : 'default'}
                                                               sortDirection={pagination.sort === row.id ? pagination.order : false}
                                                    >
                                                        {row.label}
                                                    </TableCell>
                                                ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {accountsPagination && accountsPagination.items && accountsPagination.items.map((n, index) => {
                                            return (
                                                <React.Fragment key={index++}>
                                                    <Tooltip title=''>
                                                        <TableRow hover tabIndex={-1}>
                                                            <TableCell className={classes.tableCell}>{n.id}</TableCell>
                                                            <TableCell className={classes.tableCell}>{n.description}</TableCell>
                                                            <TableCell className={classes.tableCell}>{upperFirstLetter(n.exchange)}</TableCell>
                                                            <TableCell className={classes.tableCell}>{reduceMaskText(n.apiKey)}</TableCell>
                                                            <TableCell className={classes.tableCell}>
                                                                <div className={classes.dflex}>
                                                                    <Button color={"primary"} size='sm' className={ classes.mr5 } onClick={() => this.setCurrentAccountManager(n.id)}>Add / Remove User</Button>
                                                                </div>
                                                            </TableCell>

                                                        </TableRow>
                                                    </Tooltip>
                                                </React.Fragment>
                                            );
                                        })}
                                    </TableBody>
                                </Table>}
                            </div>

                            <TablePagination
                                rowsPerPageOptions={[10, 20, 50]}
                                component="div"
                                count={accountsPagination.totalItem}
                                rowsPerPage={pagination.size}
                                page={pagination.page}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangePageSize}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
};

export default withStyles({ ...extendedFormsStyle, ...styles })(inject('dashboardStore', 'userStore')(withRouter(observer(ManageApiKeys))));
