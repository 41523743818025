import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import Hidden from "@material-ui/core/Hidden";

import sidebarStyle from "../../assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";

import defaultImage from "../../assets/img/default-avatar.png";
import { inject, observer } from "mobx-react";
import { Tooltip } from "@material-ui/core";

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
    }
    render() {
        const { className, user, headerLinks, links } = this.props;
        return (
            <div className={className} ref="sidebarWrapper">
                {user}
                {headerLinks}
                {links}
            </div>
        );
    }
}

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openAvatar: false,
            miniActive: true,
            ...this.getCollapseStates(props.routes)
        };
    }
    // this creates the intial state of this component based on the collapse routes
    // that it gets through this.props.routes
    getCollapseStates = routes => {
        let initialState = {};
        routes.forEach(prop => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: this.getCollapseInitialState(prop.views),
                    ...this.getCollapseStates(prop.views),
                    ...initialState
                };
            }
            return null;
        });
        return initialState;
    };
    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
    getCollapseInitialState(routes) {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (window.location.href.indexOf(routes[i].path) !== -1) {
                return true;
            }
        }
        return false;
    }
    // verifies if routeName is the one active (in browser input)
    activeRoute = routeName => {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };
    openCollapse(collapse) {
        var st = {};
        st[collapse] = !this.state[collapse];
        this.setState(st);
    }
    // this function creates the links and collapses that appear in the sidebar (left menu)
    createLinks = routes => {
        const { classes, color, userStore } = this.props;
        return routes.map((prop, key) => {
            if (prop.redirect) {
                return null;
            }
            if (prop.collapse) {
                var st = {};
                st[prop["state"]] = !this.state[prop.state];
                const navLinkClasses =
                    classes.itemLink +
                    " " +
                    cx({
                        [" " + classes.collapseActive]: this.getCollapseInitialState(
                            prop.views
                        )
                    });
                const itemText =
                    classes.itemText +
                    " " +
                    cx({
                        [classes.itemTextMini]:
                            this.props.miniActive && this.state.miniActive
                    });
                const collapseItemText =
                    classes.collapseItemText +
                    " " +
                    cx({
                        [classes.collapseItemTextMini]:
                            this.props.miniActive && this.state.miniActive
                    });
                const itemIcon = classes.itemIcon;
                const caret = classes.caret;
                const collapseItemMini = classes.collapseItemMini;
                return (
                    <ListItem
                        key={key}
                        className={cx(
                            { [classes.item]: prop.icon !== undefined },
                            { [classes.collapseItem]: prop.icon === undefined }
                        )}
                    >
                        <NavLink
                            to={"#"}
                            className={navLinkClasses}
                            onClick={e => {
                                e.preventDefault();
                                this.setState(st);
                            }}
                        >
                            {prop.icon !== undefined ? (
                                typeof prop.icon === "string" ? (
                                    <Icon className={itemIcon}>{prop.icon}</Icon>
                                ) : (
                                        <prop.icon className={itemIcon} />
                                    )
                            ) : (
                                    <span className={collapseItemMini}>{prop.mini}</span>
                                )}
                            <ListItemText
                                primary={prop.name}
                                secondary={
                                    <b
                                        className={
                                            caret +
                                            " " +
                                            (this.state[prop.state] ? classes.caretActive : "")
                                        }
                                    />
                                }
                                disableTypography={true}
                                className={cx(
                                    { [itemText]: prop.icon !== undefined },
                                    { [collapseItemText]: prop.icon === undefined }
                                )}
                            />
                        </NavLink>
                        <Collapse in={this.state[prop.state]} unmountOnExit>
                            <List className={classes.list + " " + classes.collapseList}>
                                {this.createLinks(prop.views)}
                            </List>
                        </Collapse>
                    </ListItem>
                );
            }
            const innerNavLinkClasses =
                classes.collapseItemLink +
                " " +
                cx({
                    [" " + classes[color]]: this.activeRoute(prop.path)
                });
            const collapseItemMini = classes.collapseItemMini;
            const navLinkClasses =
                classes.itemLink +
                " " +
                cx({
                    [" " + classes[color]]: this.activeRoute(prop.path)
                });
            const itemText =
                classes.itemText +
                " " +
                cx({
                    [classes.itemTextMini]: this.props.miniActive && this.state.miniActive
                });
            const collapseItemText =
                classes.collapseItemText +
                " " +
                cx({
                    [classes.collapseItemTextMini]:
                        this.props.miniActive && this.state.miniActive
                });
            const itemIcon = classes.itemIcon;
            return (
                <ListItem
                    key={key}
                    className={cx(
                        { [classes.item]: prop.icon !== undefined },
                        { [classes.collapseItem]: prop.icon === undefined }
                    )}
                >
                    <NavLink
                        to={prop.path}
                        className={cx(
                            { [navLinkClasses]: prop.icon !== undefined },
                            { [innerNavLinkClasses]: prop.icon === undefined }
                        )}
                    >
                        {prop.icon !== undefined ? (
                            typeof prop.icon === "string" ? (
                                <Icon className={itemIcon}>{prop.icon}</Icon>
                            ) : (
                                    <prop.icon className={itemIcon} />
                                )
                        ) : (
                                <span className={collapseItemMini}>{prop.mini}</span>
                            )}
                        <ListItemText
                            primary={prop.name}
                            disableTypography={true}
                            className={cx(
                                { [itemText]: prop.icon !== undefined },
                                { [collapseItemText]: prop.icon === undefined }
                            )}
                        />
                        {prop.hasBadge && userStore.statisticalSummary[prop.badgeField] > 0 && <span className={classes.notifications}>{userStore.statisticalSummary[prop.badgeField]}</span>}
                    </NavLink>
                </ListItem>
            );
        });
    };

    logout = () => {
        this.props.userStore.logout();
    }

    showBreachOfBusinessModal = () => {
        this.props.showBreachOfBusinessModal();
    }

    render() {
        const { classes, logo, bgSidebar, logoText, routes, bgColor, userStore } = this.props;
        const itemText =
            classes.itemText +
            " " +
            cx({
                [classes.itemTextMini]: this.props.miniActive && this.state.miniActive
            });
        const collapseItemText =
            classes.collapseItemText +
            " " +
            cx({
                [classes.collapseItemTextMini]:
                    this.props.miniActive && this.state.miniActive
            });
        const userWrapperClass =
            classes.user +
            " " +
            cx({
                [classes.whiteAfter]: bgColor === "white"
            });
        const caret = classes.caret;
        const photo = classes.photo;
        var user = (
            <div className={userWrapperClass}>
                <List className={classes.list}>
                    <ListItem className={classes.item + " " + classes.userItem}>
                        <NavLink
                            to={"#"}
                            className={classes.itemLink + " " + classes.userCollapseButton}
                            onClick={() => this.openCollapse("openAvatar")}
                            style={{textDecoration: 'none'}}
                        >
                            <div className={photo}>
                                <img src={defaultImage} className={classes.avatarImg} alt="..." />
                            </div>
                            <div>
                                <ListItemText
                                    primary={userStore && userStore.currentUser && userStore.currentUser.username}
                                    secondary={
                                        <b
                                            className={
                                                caret +
                                                " " +
                                                classes.userCaret +
                                                " " +
                                                (this.state.openAvatar ? classes.caretActive : "")
                                            }
                                        />
                                    }
                                    disableTypography={true}
                                    className={itemText + " " + classes.userItemText}
                                />

                                <div style={{ display: 'flex' }}>
                                    <div>Email: &nbsp;</div>
                                    <Tooltip
                                        title={userStore.currentUser && userStore.currentUser && userStore.currentUser.email}
                                    >
                                        <div className="truncate-text" style={{ maxWidth: '135px', whiteSpace: 'initial', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {userStore && userStore.currentUser && userStore.currentUser.email}
                                        </div>
                                    </Tooltip>
                                </div>

                                <div>
                                    Role: {userStore && userStore.currentUser && userStore.currentUser.role}
                                </div>

                            </div>
                        </NavLink>
                        <Collapse in={this.state.openAvatar} unmountOnExit>
                            <List className={classes.list + " " + classes.collapseList}>
                                <ListItem className={classes.collapseItem}>
                                    <NavLink
                                        to="/change-password"
                                        className={
                                            classes.itemLink + " " + classes.userCollapseLinks
                                        }
                                    >
                                        <ListItemText
                                            primary={"Change password"}
                                            disableTypography={true}
                                            className={collapseItemText}
                                        />
                                    </NavLink>
                                </ListItem>
                                <ListItem className={classes.collapseItem}>
                                    <NavLink
                                        to="/"
                                        onClick={this.logout}
                                        className={
                                            classes.itemLink + " " + classes.userCollapseLinks
                                        }
                                    >
                                        <ListItemText
                                            primary={"Logout"}
                                            disableTypography={true}
                                            className={collapseItemText}
                                        />
                                    </NavLink>
                                </ListItem>
                            </List>
                        </Collapse>
                    </ListItem>
                </List>
            </div>
        );
        var links = (
            <List className={classes.list}>{this.createLinks(routes)}</List>
        );

        const logoClasses =
            classes.logo +
            " " +
            cx({
                [classes.whiteAfter]: bgColor === "white"
            });
        var brand = (
            <div className={logoClasses}>
                <img src={logo} alt={logoText} className={classes.img} />
            </div>
        );

        const drawerPaper =
            classes.drawerPaper +
            " " +
            cx({
                [classes.drawerPaperMini]:
                    this.props.miniActive && this.state.miniActive
            });

        const sidebarWrapper =
            classes.sidebarWrapper +
            " " +
            cx({
                [classes.drawerPaperMini]:
                    this.props.miniActive && this.state.miniActive,
                [classes.sidebarWrapperWithPerfectScrollbar]:
                    navigator.platform.indexOf("Win") > -1
            });
        return (
            <div ref="mainPanel" style={{ color: "white" }}>
                <Hidden mdUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={"right"}
                        open={this.props.open}
                        classes={{
                            paper: drawerPaper + " " + classes[bgColor + "Background"]
                        }}
                        onClose={this.props.handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}
                    >
                        {/* <div className={classes.sidebarContainer}> */}
                        {/*{brand}*/}
                        <SidebarWrapper
                            className={sidebarWrapper}
                            user={user}
                            links={links}
                        />
                        {bgSidebar !== undefined ? (
                            <div
                                className={classes.background}
                                style={{ backgroundImage: "url(" + bgSidebar + ")" }}
                            />
                        ) : null}
                        {/*<div className={classes.bottomWrapper}><button className={classes.bottomBtn} onClick={this.showBreachOfBusinessModal}>Breach of Business Contract terms</button></div>*/}
                        {/* </div> */}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        onMouseOver={() => this.setState({ miniActive: false })}
                        onMouseOut={() => this.setState({ miniActive: true })}
                        anchor={"left"}
                        variant="permanent"
                        open
                        classes={{
                            paper: drawerPaper + " " + classes[bgColor + "Background"]
                        }}
                    >
                        <div className={classes.sidebarContainer}>
                            {/*{brand}*/}
                            <SidebarWrapper
                                className={sidebarWrapper}
                                user={user}
                                links={links}
                            />
                            {bgSidebar !== undefined ? (
                                <div
                                    className={classes.background}
                                    style={{ backgroundImage: "url(" + bgSidebar + ")" }}
                                />
                            ) : null}
                            {/*<div className={classes.bottomWrapper}><button className={classes.bottomBtn} onClick={this.showBreachOfBusinessModal}>Breach of Business Contract terms</button></div>*/}
                        </div>

                    </Drawer>
                </Hidden>
            </div>
        );
    }
}

Sidebar.defaultProps = {
    bgColor: "blue"
};

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    bgColor: PropTypes.oneOf(["white", "black", "blue", "transparent"]),
    color: PropTypes.oneOf([
        "white",
        "red",
        "orange",
        "green",
        "blue",
        "purple",
        "rose"
    ]),
    logo: PropTypes.string,
    logoText: PropTypes.string,
    bgSidebar: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(sidebarStyle)(inject('userStore')(observer(Sidebar)));
