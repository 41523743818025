import {
    changePasswordApi,
    getProfileApi,
    verifyAccountApi,
    addNewAccountApi,
    updateTelegramSettingApi,
    getListUserSubscribeTelegramApi,
    updateStatusTelegramUserApi,
    getAccountByIdApi,
    getOpenOrdersByAccountIdApi,
    updateAccountByIdApi,
    removeAccountByIdApi,
    getTradeHistoryByAccountIdApi,
    getBalancesByAccountIdApi,
    getSymbolSupportPairApi,
    createUserDataStreamApi,
    getFutureBalancesByAccountIdApi,
    getFutureOpenOrderByAccountIdApi,
    getFutureTradeHistoryByAccountIdApi,
    getOkxSymbolSupportPairApi,
} from "../dataServices/userData.service";

const changePassword = data => {
    return changePasswordApi(data);
};

const getProfile = () => {
    return getProfileApi();
};

const createUserDataStream = () => {
    return createUserDataStreamApi();
};

const verifyAccount = request => {
    return verifyAccountApi(request);
};

const addNewAccount = request => {
    return addNewAccountApi(request);
}

const updateTelegramSetting = request => {
    return updateTelegramSettingApi(request);
};

const getListUserSubscribeTelegram = (page, size) => {
    return getListUserSubscribeTelegramApi(page, size);
}

const updateStatusTelegramUser = (id) => {
    return updateStatusTelegramUserApi(id);
};

const getAccountById = id => {
    return getAccountByIdApi(id);
};

const updateAccountById = (id, request) => {
    return updateAccountByIdApi(id, request);
};

const removeAccountById = (id, data) => {
    return removeAccountByIdApi(id, data);
};

const getOpenOrdersByAccountId = id => {
    return getOpenOrdersByAccountIdApi(id);
}

const getTradeHistoryByAccountId = (id, symbol) => {
    return getTradeHistoryByAccountIdApi(id, symbol);
}

const getBalancesByAccountId = id => {
    return getBalancesByAccountIdApi(id);
}

const getFutureBalancesByAccId = id => {
    return getFutureBalancesByAccountIdApi(id);
}

const getFutureOpenOrdersByAccId = (id, pair) => {
    return getFutureOpenOrderByAccountIdApi(id, pair);
}

const getFutureTradeHistoryByAccId = (id, symbol) => {
    return getFutureTradeHistoryByAccountIdApi(id, symbol);
}

const getSymbolSupport = () => {
    return getSymbolSupportPairApi();
}

const getOkxSymbolSupport = (instType) => {
    return getOkxSymbolSupportPairApi(instType);
}

export {
    changePassword,
    getProfile,
    verifyAccount,
    addNewAccount,
    removeAccountById,
    updateTelegramSetting,
    getListUserSubscribeTelegram,
    updateStatusTelegramUser,
    getAccountById,
    updateAccountById,
    getOpenOrdersByAccountId,
    getBalancesByAccountId,
    getTradeHistoryByAccountId,
    getFutureBalancesByAccId,
    getFutureOpenOrdersByAccId,
    getFutureTradeHistoryByAccId,
    getSymbolSupport,
    createUserDataStream,
    getOkxSymbolSupport,
};
