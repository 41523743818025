import React from "react";
import { withRouter } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import notificationService from '../../services/notificationService';
import CustomInput from "../../components/CustomInput/CustomInput";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {exchangeList, FUND_TYPE} from "../../services/utils";
import CardIcon from "../../components/Card/CardIcon";
import {Assignment} from "@material-ui/icons";
import BackPreviousPageButton from "../../components/BackPreviousPageButton";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Card from "../../components/Card/Card";
import {cardTitle} from "../../assets/jss/material-dashboard-pro-react";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import Button from "../../components/CustomButtons/Button";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    header: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    newBtn: {
        marginTop: '15px',
        marginLeft: 'auto'
    },
    mb20: {
        marginBottom: '20px'
    },
    status: {
        borderRadius: '3px',
        textAlign: 'center',
        padding: '5px 10px',
        color: 'white'
    },
    sentStatus: {
        backgroundColor: '#00acc1'
    },
    closedStatus: {
        backgroundColor: 'gray'
    },
    expiredStatus: {
        backgroundColor: 'red'
    },
    tableCell: {
        fontSize: '1rem',
        padding: '4px 20px'
    },
    label: {
        fontWeight: "bold",
        marginTop: "10px",
        marginBottom: "10px"
    },
    mbt10: {
        marginTop: "10px",
        marginBottom: "10px",
    },
    titleModal: {
        fontWeight: "bold",
        fontSize: "16px"
    },
    actionButton: {
        paddingLeft: "10px",
        paddingRight: "10px"
    },
};

class AddNewAccount extends React.Component {
    state = {
        submitting: false
    }
    componentDidMount() {
        const { userStore } = this.props;
        if (userStore.currentUser) {
            userStore.addNewAccountForm.set({exchange: 'BINANCE'});
            userStore.addNewAccountForm.set({fundType: '0'});
        }
    }

    addNewAccount = async () => {
        const { addNewAccountForm } = this.props.userStore;
        if (!addNewAccountForm.isValid) return addNewAccountForm.showErrors(true);
        const addNewForm = addNewAccountForm.values();
        console.log('addNewForm', addNewForm)

        const request = {
            exchange: addNewForm.exchange.toUpperCase(),
            fundType: addNewForm.fundType,
            apiKey: addNewForm.addressKey,
            secretKey: addNewForm.secretKey,
            passphrase: addNewForm.passphrase,
            description: addNewForm.description,
            note: addNewForm.note,
            initialCap: addNewForm.initialBalance
        };
        this.setState({ submitting: true });

        const res = await this.props.userStore.createRequestAddNewAccount(request);
        console.log('confirmAddAccount res', res);
        if (res.code === 200) {
            notificationService.success('MSG_SUCCESSFUL');
            this.setState({ submitting: false });
            this.props.userStore.reset();
            this.props.history.push('/dashboard');
        } else {
            notificationService.error(res.message);
            this.setState({ submitting: false });
        }
    }

    render() {
        const { classes } = this.props;
        const { addNewAccountForm } = this.props.userStore;

        return (
            <GridContainer justify="center">
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon className={classes.header}>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Add new</h4>
                        </CardHeader>

                        <CardBody>
                            <GridItem xs={12} sm={12} md={12} lg={10}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                >
                                    <InputLabel
                                        htmlFor="simple-select"
                                        className={classes.selectLabel}
                                        style={{fontWeight: 400, fontSize: '1rem'}}
                                    >
                                        Fund
                                    </InputLabel>
                                    <Select
                                        MenuProps={{
                                            className: classes.selectMenu
                                        }}
                                        classes={{
                                            select: classes.select
                                        }}
                                        inputProps={{
                                            name: "simpleSelect",
                                            id: "simple-select"
                                        }}
                                        value={addNewAccountForm.$('fundType').value}
                                        onChange={addNewAccountForm.$('fundType').onChange}
                                    >
                                        {FUND_TYPE.map(item =>
                                            <MenuItem
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelected
                                                }}
                                                value={item.code}
                                                key={item.code}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={10}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                >
                                    <InputLabel
                                        htmlFor="simple-select"
                                        className={classes.selectLabel}
                                        style={{fontWeight: 400, fontSize: '1rem'}}
                                    >
                                        Exchange
                                    </InputLabel>
                                    <Select
                                        MenuProps={{
                                            className: classes.selectMenu
                                        }}
                                        classes={{
                                            select: classes.select
                                        }}
                                        inputProps={{
                                            name: "simpleSelect",
                                            id: "simple-select"
                                        }}
                                        value={addNewAccountForm.$('exchange').value}
                                        onChange={addNewAccountForm.$('exchange').onChange}
                                    >
                                        {exchangeList.map(item =>
                                            <MenuItem
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelected
                                                }}
                                                value={item.code}
                                                key={item.code}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={10}>
                                <CustomInput
                                    labelText='Address Key*'
                                    error={!!addNewAccountForm.$('addressKey').error}
                                    helpText={addNewAccountForm.$('addressKey').error || undefined}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={
                                        addNewAccountForm.$('addressKey').bind()
                                    }
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={10}>
                                <CustomInput
                                    labelText='Secret Key*'
                                    error={!!addNewAccountForm.$('secretKey').error}
                                    helpText={addNewAccountForm.$('secretKey').error || undefined}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={
                                        addNewAccountForm.$('secretKey').bind()
                                    }
                                />
                            </GridItem>
                            { addNewAccountForm.$('exchange').value !== 'OKX' ? <div></div> : <GridItem xs={12} sm={12} md={12} lg={10}>
                                <CustomInput
                                    labelText='Passphrase *'
                                    error={!!addNewAccountForm.$('passphrase').error}
                                    helpText={addNewAccountForm.$('passphrase').error || undefined}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={
                                        addNewAccountForm.$('passphrase').bind()
                                    }
                                />
                            </GridItem> }
                            <GridItem xs={12} sm={12} md={12} lg={10}>
                                <CustomInput
                                    labelText='Description*'
                                    error={!!addNewAccountForm.$('description').error}
                                    helpText={addNewAccountForm.$('description').error || undefined}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={
                                        addNewAccountForm.$('description').bind()
                                    }
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={10}>
                                <CustomInput
                                    labelText='Initial Balance*'
                                    error={!!addNewAccountForm.$('initialBalance').error}
                                    helpText={addNewAccountForm.$('initialBalance').error || undefined}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={
                                        addNewAccountForm.$('initialBalance').bind()
                                    }
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={10}>
                                <CustomInput
                                    labelText='Note'
                                    error={!!addNewAccountForm.$('note').error}
                                    helpText={addNewAccountForm.$('note').error || undefined}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        multiline: true,
                                        rows: 3,
                                        ...addNewAccountForm.$('note').bind()
                                    }}
                                />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12} lg={10}>
                                <GridContainer>
                                    <GridItem xs={12} sm={4}>
                                        <div style={{marginLeft: 'auto', marginTop: '15px'}}>
                                            <BackPreviousPageButton />
                                        </div>
                                    </GridItem>
                                    <GridItem>
                                        <div>
                                            <Button
                                                color="primary"
                                                className={classes.mr10}
                                                onClick={this.addNewAccount}
                                                loading={this.state.submitting}
                                                disabled={this.state.submitting}
                                            >
                                                Create
                                            </Button>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}

export default withStyles({ ...extendedFormsStyle, ...styles })(inject('userStore')(withRouter(observer(AddNewAccount))));
