import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import routes from "../routes/authRoutes";
// import logo from "../assets/img/app_logo.png";

import appStyle from "../assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";

import bgSidebar from "../assets/img/background_sidebar.png";
import { observer, inject } from "mobx-react";
import {ADMIN_ROLE} from "../services/utils";

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      bgSidebar: bgSidebar,
      color: "orange",
      bgColor: "transparent",
      hasImage: true,
      helpModal: null,
    };
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        const hash = window.location.hash.slice(1);
        if (
          routes[i].regex.test(hash)
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      return (
          <Route
              path={prop.path}
              component={prop.component}
              key={key}
              exact
          />
      );
    });
  };
  getSidebarRoutes = routes => {
    const { currentUser } = this.props.userStore;
    return routes.filter(prop => prop.showInSidebar || (currentUser && ADMIN_ROLE.indexOf(currentUser.role) !== -1 && prop.showForAdmin));
  }

  createMarkup = (text) => { return { __html: text }; };

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  render() {
    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={this.getSidebarRoutes(routes)}
          logoText={"WatchTower"}
          // logo={logo}
          bgSidebar={this.state.bgSidebar}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          bgColor={this.state.bgColor}
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
          <AdminNavbar
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            brandText={this.getActiveRoute(routes)}
            handleDrawerToggle={this.handleDrawerToggle}
            showHelpModal={this.showHelpModal}
            showFeedbackModal={this.showFeedbackModal}
            showTermConditionsModal={this.showTermConditionsModal}
            showPrivacyPolicyModal={this.showPrivacyPolicyModal}
            {...rest}
          />
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>{this.getRoutes(routes)}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(appStyle)(inject('commonStore', 'userStore')((observer(Dashboard))));
