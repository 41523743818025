import requests from "../http";
import {convertPagingResponse} from "../utils";
import axios from "axios";

const changePasswordApi = data => {
    return requests.post("/user/change-password", data);
};

const getProfileApi = () => {
    return requests.get("/user/profile");
};

const createUserDataStreamApi = () => {
    return requests.post("/userDataStream");
};

const verifyAccountApi = data => {
    return requests.post(`/dashboard/verify-account`, data);
};

const addNewAccountApi = data => {
    return requests.post(`/watch`, data);
}

const updateTelegramSettingApi = data => {
    return requests.post(`/telegram`, data);
};

const getListUserSubscribeTelegramApi = (page, size) => {
    return requests.get(`/telegram/list-user?page=${++page}&size=${size}`).then(data => convertPagingResponse(data));
};

const updateStatusTelegramUserApi = (id) => {
    return requests.post(`/telegram/list-user/${id}`);
};

const getAccountByIdApi = (id) => {
    return requests.get(`/watch/${id}`);
};

const updateAccountByIdApi = (id, data) => {
    return requests.post(`/watch/${id}`, data);
};

const removeAccountByIdApi = (id, data) => {
    return requests.post(`/watch/${id}/delete`, data);
}

const getOpenOrdersByAccountIdApi = (id) => {
    return requests.get(`/watch/${id}/open-order`);
};

const getTradeHistoryByAccountIdApi = (id, symbol) => {
    return requests.get(`/watch/${id}/trade-history?symbol=${symbol}`);
};

const getBalancesByAccountIdApi = (id) => {
    return requests.get(`/watch/${id}/balance`);
};

const getFutureBalancesByAccountIdApi = (id) => {
    return requests.get(`/watch/${id}/fbalance`);
};

const getFutureOpenOrderByAccountIdApi = (id, symbol) => {
    return requests.get(`/watch/${id}/fopen-order?symbol=${symbol}`);
};

const getFutureTradeHistoryByAccountIdApi = (id, symbol) => {
    return requests.get(`/watch/${id}/ftrade-history?symbol=${symbol}`);
};

const getSymbolSupportPairApi = () => {
    return axios.get('https://api.binance.com/api/v3/exchangeInfo?permissions=SPOT');
    // return requests.getSymbolSupport(``);
}

const getOkxSymbolSupportPairApi = (instType) => {
    return axios.get(`https://aws.okx.com/api/v5/public/instruments?instType=${instType}`);
}

export {
    changePasswordApi,
    getProfileApi,
    verifyAccountApi,
    addNewAccountApi,
    removeAccountByIdApi,
    updateTelegramSettingApi,
    getListUserSubscribeTelegramApi,
    updateStatusTelegramUserApi,
    getAccountByIdApi,
    updateAccountByIdApi,
    getOpenOrdersByAccountIdApi,
    getBalancesByAccountIdApi,
    getTradeHistoryByAccountIdApi,
    getFutureBalancesByAccountIdApi,
    getFutureOpenOrderByAccountIdApi,
    getFutureTradeHistoryByAccountIdApi,
    getSymbolSupportPairApi,
    createUserDataStreamApi,
    getOkxSymbolSupportPairApi,
};
