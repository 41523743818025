import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";

import {inject, observer} from 'mobx-react';
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import GridContainer from "../../components/Grid/GridContainer";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import notificationService from "../../services/notificationService";

const styles = {
    passwordHint: {
        color: '#F8981F'
    },
    btnWrapper: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}

class ChangePassword extends React.Component {
    state = {
        submitting: false,
    }

    submitChangePassword = (e) => {
        e.preventDefault();
        const { accountSettingsForm, changePassword } = this.props.userStore;
        // if (userRegisterForm.$('agreeTerm').value) {
        if (!accountSettingsForm.isValid) {
            return accountSettingsForm.showErrors(true);
        }

        accountSettingsForm.submit({
            onSuccess: form => {
                const data = form.values();
                const request = {
                    oldPassword: data.current_password,
                    newPassword: data.new_password,
                }
                changePassword(request).then((res) => {
                    console.log('submitChangePassword accountSettingsForm', res);
                    if (res.code === 200) {
                        notificationService.success('MSG_SUCCESSFUL');
                    } else {
                        notificationService.error(res.message);
                    }
                }).catch(res => {
                    console.log('error ', res);
                    const err = res.response;
                    notificationService.error(err.data.message);
                });
            },
            onError: (form) => {
                // eslint-disable-next-line no-console
                console.log("All form errors", form.errors());
            }
        })
        // }
    }

    render() {
        const { classes } = this.props;
        const { accountSettingsForm, toggleShowCurrentPassword, toggleShowNewPassword, toggleShowRetypeNewPassword } = this.props.userStore;

        return (
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <Card className={classes.cardSignup}>
                        <CardBody>
                            <form className={classes.form} onSubmit={this.submitChangePassword}>
                                <GridItem xs={12} sm={12}>
                                    <CustomInput
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.customFormControlClasses
                                        }}
                                        inputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                    className={classes.inputAdornment}
                                                    onClick={toggleShowCurrentPassword}
                                                >
                                                    {accountSettingsForm.$("current_password").get("type") === "password" ? <RemoveRedEye className={classes.inputAdornmentIcon} /> :
                                                        <VisibilityOff className={classes.inputAdornmentIcon} />}
                                                </InputAdornment>
                                            ),
                                            ...accountSettingsForm.$('current_password').bind()
                                        }}
                                        error={!!accountSettingsForm.$('current_password').error}
                                        helpText={accountSettingsForm.$('current_password').error || undefined}
                                        labelText='Current Password'
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12}>
                                    <CustomInput
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.customFormControlClasses
                                        }}
                                        inputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                    className={classes.inputAdornment}
                                                    onClick={toggleShowNewPassword}
                                                >
                                                    {accountSettingsForm.$("new_password").get("type") === "password" ? <RemoveRedEye className={classes.inputAdornmentIcon} /> :
                                                        <VisibilityOff className={classes.inputAdornmentIcon} />}
                                                </InputAdornment>
                                            ),
                                            ...accountSettingsForm.$('new_password').bind()
                                        }}
                                        error={!!accountSettingsForm.$('new_password').error}
                                        helpText={accountSettingsForm.$('new_password').error || undefined}
                                        labelText='New password'
                                    />
                                    <div className={classes.passwordHint}>Password must contain at least 8 characters, one lower case letter, one upper case letter, one number and one of the following symbols: !, @, #, $, %, &, *</div>
                                </GridItem>
                                <GridItem xs={12} sm={12}>
                                    <CustomInput
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.customFormControlClasses
                                        }}
                                        inputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                    className={classes.inputAdornment}
                                                    onClick={toggleShowRetypeNewPassword}
                                                >
                                                    {accountSettingsForm.$("retype_new_password").get("type") === "password" ? <RemoveRedEye className={classes.inputAdornmentIcon} /> :
                                                        <VisibilityOff className={classes.inputAdornmentIcon} />}
                                                </InputAdornment>
                                            ),
                                            ...accountSettingsForm.$('retype_new_password').bind()
                                        }}
                                        error={!!accountSettingsForm.$('retype_new_password').error}
                                        helpText={accountSettingsForm.$('retype_new_password').error || undefined}
                                        labelText='Retype new password'
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} className={classes.btnWrapper}>
                                    <Button color="primary" round type="submit" loading={this.state.submitting} disabled={this.state.submitting}>
                                        Submit
                                    </Button>
                                </GridItem>
                            </form>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}

export default withStyles(styles)(inject('userStore')(observer(ChangePassword)));


