import { Form } from "mobx-react-form";
import plugins from "./plugins";

export default class SecondPasswordForm extends Form {
    plugins() {
        return plugins;
    }
    options() {
        return {
            showErrorsOnReset: true,
            validateOnChange: true,
            validateOnBlur: false
        };
    }

    setup() {
        return {
            fields: [
                {
                    name: "password",
                    placeholder: "Please enter password to process",
                    rules: "required",
                    type: "password",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
            ]
        }
    }
}
