import {
    registerApi,
    loginApi,
    forgotPasswordApi,
    resetPasswordApi
} from "../dataServices/authData.service";

const register = data => {
    return registerApi(data);
};

const login = data => {
    return loginApi(data);
};

const forgotPassword = data => {
    return forgotPasswordApi(data);
};

const resetPassword = data => {
    return resetPasswordApi(data);
}

export { register, login, forgotPassword, resetPassword };
