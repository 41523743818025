import { Form } from "mobx-react-form";
import plugins from "./plugins";

export default class AddUserToGroupForm extends Form {
    plugins() {
        return plugins;
    }

    setup() {
        return {
            fields: [
                {
                    name: "userId",
                    label: 'User Id',
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "userEmail",
                    label: 'User Email',
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "mngId",
                    label: 'Manage Id',
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "mngEmail",
                    placeholder: "Manage Email",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
            ]
        };
    }
}
