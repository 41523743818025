import React from 'react';
import { inject, observer } from 'mobx-react';
import withStyles from "@material-ui/core/styles/withStyles";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import {withRouter} from "react-router-dom";
import {cardTitle} from "../../assets/jss/material-dashboard-pro-react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardIcon from "../../components/Card/CardIcon";
import {List} from "@material-ui/icons";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CustomInput from "../../components/CustomInput/CustomInput";
import {
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip
} from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import notificationService from "../../services/notificationService";
import Loading from "../../components/Loading/Loading";
import * as moment from "moment-timezone/builds/moment-timezone-with-data";
import Delete from "@material-ui/icons/Delete";
import BackPreviousPageButton from "../../components/BackPreviousPageButton";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    header: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    newBtn: {
        marginTop: '15px',
        marginLeft: 'auto'
    },
    mb20: {
        marginBottom: '20px'
    },
    status: {
        borderRadius: '3px',
        textAlign: 'center',
        padding: '5px 10px',
        color: 'white'
    },
    sentStatus: {
        backgroundColor: '#00acc1'
    },
    closedStatus: {
        backgroundColor: 'gray'
    },
    expiredStatus: {
        backgroundColor: 'red'
    },
    tableCell: {
        fontSize: '1rem',
        padding: '4px 20px'
    },
    label: {
        fontWeight: "bold",
        marginTop: "10px",
        marginBottom: "10px"
    },
    mbt10: {
        marginTop: "10px",
        marginBottom: "10px",
    },
    titleModal: {
        fontWeight: "bold",
        fontSize: "16px"
    },
    actionButton: {
        paddingLeft: "10px",
        paddingRight: "10px"
    },
};

const rows = [
    { id: 'createdAt', numeric: false, disablePadding: false, label: 'Date' },
    { id: 'volume', numeric: false, disablePadding: false, label: 'Volume' },
    { id: 'price', numeric: false, disablePadding: false, label: 'Price Set' },
    { id: 'estimate', numeric: false, disablePadding: false, label: 'Estimate' },
    { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'action', numeric: false, disablePadding: false, label: 'Action' },
];

class ManualTrade extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            modal: null,
            submittingSend: false,
        };
    }

    componentDidMount() {
        this.getAllOrdersAccount();
    }
    componentWillUnmount() {
        this.props.userStore.reset();
    }

    getAllOrdersAccount = () => {
        const {getAllOpenOrders} = this.props.userStore;
        getAllOpenOrders(this.requestId);
    }

    get requestId() {
        return this.props.match.params.id;
    };

    isValidated() {
        this.props.userStore.manualTradeForm.showErrors(true);
        return this.props.userStore.manualTradeForm.isValid;
    };

    onPriceChange = (isPrice, event) => {
        console.log('onPriceChange ', event.target.value)
        this.props.userStore.changeEstimateReceive(isPrice, event.target.value);
    }

    setStopLostOrder = async () => {
        console.log('setStopLostOrder')
        if (!this.props.userStore.manualTradeForm.isValid) {
            notificationService.error('MSG_INVALID_INPUT');
            return;
        }
        const manualTradeFormValues = this.props.userStore.manualTradeForm.values();
        console.log('manualTradeFormValues', manualTradeFormValues);

        const request = {
            symbol: manualTradeFormValues.symbol,
            price: manualTradeFormValues.targetValue,
            stopPrice: manualTradeFormValues.price,
            qty: manualTradeFormValues.volume,
        };

        this.setState({ submittingSend: true });
        const res = await this.props.userStore.createRequestPlaceOcoOrder(this.requestId, request);
        console.log('confirmAddAccount res', res);
        if (res.code === 0) {
            notificationService.success('MSG_SUCCESSFUL');
            this.props.userStore.accountAllOrderList.push(res.data);
        } else {
            notificationService.error(res.message);
        }
        this.setState({ submittingSend: false });
    };

    cancelPendingOrder = async (symbol, orderId) => {
        if (!orderId || orderId === -1) {
            notificationService.error('MSG_INVALID_INPUT')
            return;
        }
        const request = {
            symbol: symbol,
            orderId: orderId,
        };
        this.setState({ submittingSend: true });
        const res = await this.props.userStore.createRequestCancelOcoOrder(this.requestId, request);
        console.log('confirmAddAccount res', res);
        if (res.code === 0) {
            notificationService.success('MSG_SUCCESSFUL');
            this.props.userStore.accountAllOrderList = this.props.userStore.accountAllOrderList.filter((o) => {
                if (o.orderId === orderId) o.status = 'CANCELED';
                return o;
            });
        } else {
            notificationService.error(res.message);
        }
        this.setState({ submittingSend: false });
    }

    render() {
        const { classes } = this.props;
        const { manualTradeForm, isOpenOrdersLoading, accountAllOrderList } = this.props.userStore;
        const { currentPrice } = this.props.dashboardStore;
        const symbol = manualTradeForm.$("symbol").value;

        return (
            <GridContainer justify="center">
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon className={classes.header}>
                            <CardIcon color="primary">
                                <List />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Manual Trade Window</h4>
                            <div style={{marginLeft: 'auto', marginTop: '15px'}}>
                                <BackPreviousPageButton />
                            </div>

                        </CardHeader>
                        <CardBody>
                            <GridItem xs={12} sm={12} md={12} lg={5}>
                                <CustomInput
                                    labelText='Account Name'
                                    error={!!manualTradeForm.$('accName').error}
                                    helpText={manualTradeForm.$('accName').error || undefined}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={
                                        manualTradeForm.$('accName').bind()
                                    }
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={5}>
                                <CustomInput
                                    labelText='Balance Token'
                                    error={!!manualTradeForm.$('balanceToken').error}
                                    helpText={manualTradeForm.$('balanceToken').error || undefined}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={
                                        manualTradeForm.$('balanceToken').bind()
                                    }
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={5}>
                                <CustomInput
                                    labelText='Target Sell Value'
                                    error={!!manualTradeForm.$('targetValue').error}
                                    helpText={manualTradeForm.$('targetValue').error || undefined}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={
                                        manualTradeForm.$('targetValue').bind()
                                    }
                                />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12} lg={5}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <GridContainer>
                                            <GridItem xs={4}>
                                                <div className={classes.label}>Bid:</div>
                                            </GridItem>
                                            <GridItem xs={8}>
                                                <p className={classes.mbt10}> {currentPrice[symbol] && currentPrice[symbol].bid} </p>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <GridContainer>
                                            <GridItem xs={4}>
                                                <div className={classes.label}>Ask:</div>
                                            </GridItem>
                                            <GridItem xs={8}>
                                                <p className={classes.mbt10}> {currentPrice[symbol] && currentPrice[symbol].ask} </p>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12} lg={5}>
                                <CustomInput
                                    labelText='Sell Volume*'
                                    error={!!manualTradeForm.$('volume').error}
                                    helpText={manualTradeForm.$('volume').error || undefined}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        ...manualTradeForm.$('volume').bind(),
                                        // onChange: (e) => this.onPriceChange(false, e),
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={5}>
                                <CustomInput
                                    labelText='Sell Price*'
                                    error={!!manualTradeForm.$('price').error}
                                    helpText={manualTradeForm.$('price').error || undefined}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        ...manualTradeForm.$('price').bind(),
                                        // onChange: (e) => this.onPriceChange(true, e),
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <GridContainer>
                                    <GridItem xs={12} sm={5}>
                                        {/*<CustomInput*/}
                                        {/*    labelText='Estimate receive'*/}
                                        {/*    error={!!manualTradeForm.$('estimate').error}*/}
                                        {/*    helpText={manualTradeForm.$('estimate').error || "Enter sell price and volume will auto fill this field"}*/}
                                        {/*    formControlProps={{*/}
                                        {/*        fullWidth: true*/}
                                        {/*    }}*/}
                                        {/*    inputProps={{*/}
                                        {/*        ...manualTradeForm.$('estimate').bind(),*/}
                                        {/*        disabled: true*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                        <GridContainer>
                                            <GridItem xs={4}>
                                                <div className={classes.label}>Estimate receive:</div>
                                            </GridItem>
                                            <GridItem xs={8}>
                                                {/*<p className={classes.mbt10}> {currentPrice[symbol] && manualTradeForm.$('price').value && manualTradeForm.$('volume').value && (currentPrice[symbol].bid * manualTradeForm.$('volume').value * manualTradeForm.$('price').value) } </p>*/}
                                                <p className={classes.mbt10}> {manualTradeForm.$('price').value && manualTradeForm.$('volume').value && (manualTradeForm.$('volume').value * manualTradeForm.$('price').value) } </p>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                    <GridItem xs={12} sm={3}>
                                        <div>
                                            <Button
                                                color="info"
                                                className={classes.mr10}
                                                onClick={this.setStopLostOrder}
                                                loading={this.state.submittingSend}
                                                disabled={this.state.submittingSend}
                                            >
                                                Set Stop Lost Order
                                            </Button>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <Divider className={classes.mbt10} />
                            </GridItem>

                            <div style={{ overflow: 'auto' }}>
                                {isOpenOrdersLoading ? <Loading /> : <Table aria-labelledby="tableTitle" padding="default">
                                    <TableHead>
                                        <TableRow>
                                            {rows.map(
                                                row => (
                                                    <TableCell key={row.id}
                                                               className={classes.tableCell}
                                                               align={row.numeric ? 'right' : 'left'}
                                                               padding={row.disablePadding ? 'none' : 'default'}
                                                               sortDirection={false}
                                                    >
                                                        <TableSortLabel>
                                                            {row.label}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {accountAllOrderList.map(n => {
                                            return (
                                                <React.Fragment key={n.orderId}>
                                                    <Tooltip title=''>
                                                        <TableRow hover tabIndex={-1}>
                                                            <TableCell>{moment(n.time).format('DD MMM YYYY')}</TableCell>

                                                            <TableCell className={classes.tableCell}>{n.origQty + ' ' + n.symbol}</TableCell>

                                                            <TableCell className={classes.tableCell}>{n.price}</TableCell>

                                                            <TableCell className={classes.tableCell}>{n.price * n.origQty}</TableCell>

                                                            <TableCell className={classes.tableCell}>{n.type}</TableCell>

                                                            <TableCell className={classes.tableCell}>{n.status}</TableCell>

                                                            <TableCell className={classes.tableCell}>
                                                                {n.status === 'FILLED' || n.status === 'CANCELED' ? <div> </div> :
                                                                    <Button
                                                                        color={"danger"}
                                                                        simple
                                                                        style={ styles.actionButton }
                                                                        className={ classes.actionButton }
                                                                        onClick={() =>
                                                                            this.cancelPendingOrder(n.symbol, n.orderId)
                                                                        }
                                                                    >
                                                                        <Delete className={classes.icon}/>
                                                                    </Button>
                                                                }
                                                            </TableCell>


                                                        </TableRow>
                                                    </Tooltip>
                                                </React.Fragment>
                                            );
                                        })}
                                    </TableBody>
                                </Table>}

                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}

export default withStyles({ ...extendedFormsStyle, ...styles })(inject('dashboardStore', 'userStore')(withRouter(observer(ManualTrade))));
