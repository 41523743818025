import { decorate, observable, action } from "mobx";
import {
    getAccountList, getSnapshotList, keepAlive, saveCsv, saveSnapshotCsv, saveWatchTowerCsv,
} from "../services/domainServices/dashboardDomain.service";
import * as _ from 'lodash';
import SearchFieldForm from "./form/searchField";
import {dynamicSortCustom} from "../services/utils";
import * as moment from "moment";

const initialAccountsPagination = {
    page: 0,
    items: [],
    size: 200,
    totalPage: 0,
    totalItem: 0
};

const initialPagination = {
    size: 50,
    page: 0,
    params: "",
    sort: "balance",
    order: "desc",
};

class DashboardStore {
    accountsPagination = _.cloneDeep(initialAccountsPagination);
    pagination = _.cloneDeep(initialPagination);

    snapshotPagination = _.cloneDeep(initialAccountsPagination);
    ssPagination = _.cloneDeep(initialPagination);

    isLoading = false;

    constructor() {
        this.searchFieldForm = new SearchFieldForm();
        this.searchFieldForm.set({'search': 'apiKey'});
        // this.searchFieldForm.set({'pair': 'BTCUSDT'});
    }

    updateInitPairFilter = (pair) => {
        this.searchFieldForm.set({'pair': pair});
    }

    changePageOfPagination = page => {
        this.pagination.page = page;
    };

    changePageSizeOfPagination = pageSize => {
        this.pagination.size = pageSize;
    };

    changeSortOfPagination = (sort, isNumber) => {
        let order = "desc";
        if (this.pagination.sort === sort && this.pagination.order === "desc") {
            order = "asc";
        }
        this.pagination.order = order;
        this.pagination.sort = sort;
        console.log('sort', sort, 'order', order)

        if (!this.accountsPagination || this.accountsPagination.items.length === 0) return;

        // this.accountsPagination.items = this.accountsPagination.items.slice().sort(dynamicSortCustom(sort, order, isNumber));

        const itemFixedPosition = this.accountsPagination.items.slice().filter(e => e.id === null);
        const itemSorted = this.accountsPagination.items.slice().filter(e => e.id !== null).sort(dynamicSortCustom(sort, order, isNumber));
        itemSorted.push(...itemFixedPosition);
        this.accountsPagination.items = itemSorted;
    };

    changeSortOfSnapshotPagination = (sort, isNumber) => {
        let order = "desc";
        if (this.ssPagination.sort === sort && this.ssPagination.order === "desc") {
            order = "asc";
        }
        this.ssPagination.order = order;
        this.ssPagination.sort = sort;
        console.log('sort', sort, 'order', order)

        if (!this.snapshotPagination || this.snapshotPagination.items.length === 0) return;

        // this.accountsPagination.items = this.accountsPagination.items.slice().sort(dynamicSortCustom(sort, order, isNumber));

        const itemFixedPosition = this.snapshotPagination.items.slice().filter(e => e.id === null);
        const itemSorted = this.snapshotPagination.items.slice().filter(e => e.id !== null).sort(dynamicSortCustom(sort, order, isNumber));
        itemSorted.push(...itemFixedPosition);
        this.snapshotPagination.items = itemSorted;
    };

    changeAccNamePagination = (value) => {
        console.log('changeAccNamePagination', this.searchFieldForm.values().search, value)
        this.pagination.params = value;
    }

    getAccountsList = (search, page, size) => {
        this.isLoading = true;
        return getAccountList(search, page, size).then(
            action(res => {
                // console.log('getAccountsList res', res);
                this.accountsPagination = res;
                this.accountsPagination.items.map(acc => this.calculatePnl(acc));
                this.isLoading = false;

            })
        );
    };

    getSnapshotList = (search, page, size) => {
        this.isLoading = true;
        return getSnapshotList(search, page, size).then(
            action(res => {
                // console.log('getAccountsList res', res);
                this.snapshotPagination = res;
                this.snapshotPagination.items.map(acc => this.calculatePnl(acc));
                this.isLoading = false;
            })
        );
    }

    downloadSnapshot = (paramRequest, page, size) => {
        saveSnapshotCsv(paramRequest, page, size).then(data => {
            const blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `WatchTowerSnapshotData-${moment().format('DD-MMM-YYYY HH:mm')}.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        })
    }

    calculatePnl = (account) => {
        account.fbalance = account.fbalance ? account.fbalance : 0;
        account.sumBalance = (account.fbalance * 1 + account.balance * 1).toFixed(4);

        account.pnlusdt = account.pnlusdt ? account.pnlusdt : 0;
        account.fpnlusdt = account.fpnlusdt ? account.fpnlusdt : 0;
        account.sumPnlusdt = (account.sumBalance - account.initialCap).toFixed(4);

        account.pnl = account.pnl ? account.pnl : 0;
        account.fpnl = account.fpnl ? account.fpnl : 0;
        account.sumPnl = (account.sumPnlusdt / account.initialCap).toFixed(4);

        account.ftotalUnrealizedProfit = account.ftotalUnrealizedProfit ? account.ftotalUnrealizedProfit : 0;

        return account;
    }

    callKeepAlive = (listenKey) => {
        return keepAlive(listenKey);
    }

    accountUpdateEvent = (account) => {
        if (!account || !this.accountsPagination) return;

        let updateObj = {
            diffInitCap: 0,
            diffBalance: 0,
            diffFbalance: 0,
            diffPnl: 0,
            diffPnlUsdt: 0,
            diffFPnl: 0,
            diffFPnlUsdt: 0,
            diffTotalOpenOrder: 0,
            diffFopenOrder: 0,
            diffFopenPosition: 0,
            diffFtotalUnrealizedProfit: 0,
        }

        this.accountsPagination.items = this.accountsPagination.items.map(obj => {
            if (obj.id === account.id) {
                // obj.balance = account.balance;
                // obj.totalOpenOrder = account.totalOpenOrder;
                updateObj['diffInitCap'] = account.initialCap*1 - obj.initialCap*1;
                updateObj['diffBalance'] = account.balance*1 - obj.balance*1;
                updateObj['diffFbalance'] = account.fbalance*1 - obj.fbalance*1;
                updateObj['diffPnl'] = account.pnl*1 - obj.pnl*1;
                updateObj['diffPnlUsdt'] = account.pnlusdt*1 - obj.pnlusdt*1;
                updateObj['diffFPnl'] = account.fpnl*1 - obj.fpnl*1;
                updateObj['diffFPnlUsdt'] = account.fpnlusdt*1 - obj.fpnlusdt*1;
                updateObj['diffTotalOpenOrder'] = account.totalOpenOrder*1 - obj.totalOpenOrder*1;
                updateObj['diffFopenOrder'] = account.fopenOrder*1 - obj.fopenOrder*1;
                updateObj['diffFopenPosition'] = account.fopenPosition*1 - obj.fopenPosition*1;
                updateObj['diffFtotalUnrealizedProfit'] = account.ftotalUnrealizedProfit*1 - obj.ftotalUnrealizedProfit*1;

                obj = this.calculatePnl(account);
            }

            else if ((!obj.id && obj.exchange === account.exchange && obj.fundType === account.fundType) || // update group by exchange
                (!obj.id && obj.fundType === account.fundType && obj.description.startsWith('Total')) || // update group
                (!obj.id && obj.description === 'Grand Total')) { // update grand total
                // console.log('account inc ' + account.description + '-' + account.exchange + '-' + account.fundType,  ' | update obj ' + obj.description);
                // console.log('udpateObj', updateObj);

                obj.initialCap = obj.initialCap*1 + updateObj['diffInitCap'];
                obj.balance = obj.balance*1 + updateObj['diffBalance'];
                obj.fbalance = obj.fbalance*1 + updateObj['diffFbalance'];
                obj.pnl = obj.pnl*1 + updateObj['diffPnl'];
                obj.pnlusdt = obj.pnlusdt*1 + updateObj['diffPnlUsdt'];
                obj.fpnl = obj.fpnl*1 + updateObj['diffFPnl'];
                obj.fpnlusdt = obj.fpnlusdt*1 + updateObj['diffFPnlUsdt'];
                obj.totalOpenOrder = obj.totalOpenOrder*1 + updateObj['diffTotalOpenOrder'];
                obj.fopenOrder = obj.fopenOrder*1 + updateObj['diffFopenOrder'];
                obj.fopenPosition = obj.fopenPosition*1 + updateObj['diffFopenPosition'];
                obj.ftotalUnrealizedProfit = obj.ftotalUnrealizedProfit*1 + updateObj['diffFtotalUnrealizedProfit'];
            }

            return obj;
        })
    };

    downloadHistory = (wtId, source, description, isFuture) => {
        saveCsv(wtId, source)
            .then(data => {
                const blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `AllTradeHistory-${isFuture ? `FUTURES` : `SPOT`}-${description}.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
    };

    downloadWatchTower = (paramRequest, page, size) => {
        saveWatchTowerCsv(paramRequest, page, size).then(data => {
            const blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `WatchTowerData-${moment().format('DD-MMM-YYYY HH:mm')}.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        })
    }
};

decorate(DashboardStore, {
    accountsPagination: observable,
    pagination: observable,
    snapshotPagination: observable,
    ssPagination: observable,
    isLoading: observable,
    searchFieldForm: observable,
    changePageOfPagination: action,
    updateInitPairFilter: action,
    changePageSizeOfPagination: action,
    changeAccNamePagination: action,
    getAccountsList: action,
    callKeepAlive: action,
    accountUpdateEvent: action,
    downloadHistory: action,
    downloadWatchTower: action,
    getSnapshotList: action,
    downloadSnapshot: action,
    changeSortOfSnapshotPagination: action,
});

export default new DashboardStore();
