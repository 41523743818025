import React from "react";
import { withRouter } from 'react-router-dom';
import { inject, observer } from "mobx-react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel, TableBody, Tooltip
} from "@material-ui/core";
import extendedFormsStyle from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import Loading from "../../../components/Loading/Loading";
import {cardTitle} from "../../../assets/jss/material-dashboard-pro-react";
import {toMoneyValue} from "../../../services/utils";

const style = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    header: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    newBtn: {
        marginTop: '15px',
        marginLeft: 'auto'
    },
    label: {
        fontWeight: 'bold',
        marginTop: '10px',
        marginBottom: '10px',
    },
    mbt10: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    mb20: {
        marginBottom: '20px'
    },
    status: {
        borderRadius: '3px',
        textAlign: 'center',
        padding: '5px 10px',
        color: 'white'
    },
    sentStatus: {
        backgroundColor: '#00acc1'
    },
    closedStatus: {
        backgroundColor: 'gray'
    },
    expiredStatus: {
        backgroundColor: 'red'
    },
    tableCell: {
        fontSize: '1rem',
        padding: '4px 20px'
    }
};

const balanceRows = [
    { id: 'id', numeric: false, disablePadding: false, label: 'No.', disableSort: true, isNumber: true },
    { id: 'symbol', numeric: false, disablePadding: false, label: 'Coins', disableSort: false, isNumber: false },
    { id: 'totalBalance', numeric: false, disablePadding: false, label: 'Total balance', disableSort: false, isNumber: true },
    { id: 'free', numeric: false, disablePadding: false, label: 'Available balance', disableSort: false, isNumber: true },
    { id: 'lock', numeric: false, disablePadding: false, label: 'In orders', disableSort: false, isNumber: true },
];

class SpotBalance extends React.Component {

    componentDidMount() {

    }

    get requestId() {
        return this.props.match.params.id;
    };

    handleSort = (sort, isNumber) => () => {
        this.props.userStore.changeSortOfPaginationSpotBalance(sort, isNumber);
    }

    render() {
        const { classes } = this.props;
        const { isBalanceLoading, accountBalanceList, paginationSpotBalance } = this.props.userStore;

        return (
            <GridItem>
                <div style={{ overflow: 'auto' }}>
                    {isBalanceLoading ? <Loading /> : <Table aria-labelledby="tableTitle" padding="default">
                        <TableHead>
                            <TableRow>
                                {balanceRows.map(
                                    row => (
                                        <TableCell key={row.id}
                                                   className={classes.tableCell}
                                                   align={row.numeric ? 'right' : 'left'}
                                                   padding={row.disablePadding ? 'none' : 'default'}
                                                   sortDirection={paginationSpotBalance.sort === row.id ? paginationSpotBalance.order : false}
                                        >
                                            {!row.disableSort ? <Tooltip
                                                    title="Sort"
                                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                                    enterDelay={300}
                                                >
                                                    <TableSortLabel
                                                        active={paginationSpotBalance.sort === row.id}
                                                        direction={paginationSpotBalance.order}
                                                        onClick={this.handleSort(row.id, row.isNumber)}
                                                    >
                                                        {row.label}
                                                    </TableSortLabel>
                                                </Tooltip> :
                                                <TableSortLabel>{row.label}</TableSortLabel>
                                            }
                                        </TableCell>
                                    ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {accountBalanceList && accountBalanceList.map((n, key) => {
                                return (
                                    <React.Fragment key={n.asset}>
                                        <Tooltip title=''>
                                            <TableRow hover tabIndex={-1}>
                                                <TableCell className={classes.tableCell}>{key + 1}</TableCell>
                                                <TableCell className={classes.tableCell}>{n.asset}</TableCell>
                                                <TableCell className={classes.tableCell}>{toMoneyValue(n.free*1 + n.locked*1)}</TableCell>
                                                <TableCell className={classes.tableCell}>{toMoneyValue(n.free)}</TableCell>
                                                <TableCell className={classes.tableCell}>{toMoneyValue(n.locked)}</TableCell>
                                            </TableRow>
                                        </Tooltip>
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>}
                </div>
            </GridItem>
        );
    }
}

export default withStyles({ ...extendedFormsStyle, ...style })(inject('userStore')(withRouter(observer(SpotBalance))));
