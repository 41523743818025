import LoginPage from "../views/LoginPage.jsx";

// @material-ui/icons
import Image from "@material-ui/icons/Image";
import UserRegister from "../views/Register/UserRegister";

const unauthRoutes = [
    {
        collapse: true,
        name: "Pages",
        icon: Image,
        state: "pageCollapse",
        views: [
            {
                path: "/login-page",
                name: "Login Page",
                mini: "L",
                component: LoginPage,
                layout: "/auth"
            },
            // {
            //     path: "/register-page",
            //     name: "Register Page",
            //     mini: "R",
            //     component: UserRegister,
            //     layout: "/auth"
            // },
        ]
    }
];
export default unauthRoutes;
