import { Form } from "mobx-react-form";
import plugins from "./plugins";

export default class AddNewAccountForm extends Form {
    plugins() {
        return plugins;
    }
    options() {
        return {
            showErrorsOnReset: true,
            validateOnChange: true,
            validateOnBlur: false
        };
    }

    setup() {
        return {
            fields: [
                {
                    name: "exchange",
                    placeholder: "Select your exchange",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "fundType",
                    placeholder: "Select fund type",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "addressKey",
                    placeholder: "Enter your address key",
                    rules: "required",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "secretKey",
                    placeholder: "Enter your secret key",
                    rules: "required",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "passphrase",
                    placeholder: "Enter your passphrase",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "description",
                    placeholder: "Enter your description",
                    rules: "required",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "initialBalance",
                    placeholder: "Enter your initial balance",
                    type: "number",
                    rules: "required",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "note",
                    placeholder: "Add some notes...",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
            ]
        }
    }
}
