import { convertPagingResponse } from "../utils";
import requests from "../http";

const getAccountListApi = (search, page, size) => {
    return requests
        .get(
            `/watch?page=${page}&size=${size}${search ? `&${search}` : ''}`
        )
        .then(data => convertPagingResponse(data));
};

const getSnapshotListApi = (search, page, size) => {
    return requests
        .get(
            `/report/dashboard-snapshot?page=${page}&size=${size}${search ? `&${search}` : ''}`
        )
        .then(data => convertPagingResponse(data));
};

const saveSnapshotCsvApi = (search, page, size) => {
    return requests.getBlob(`/report/dashboard-snapshot/export?page=${page}&size=${size}${search ? `&${search}` : ''}`)
};

const keepAliveApi = (listenKey) => {
    return requests.post(`/userDataStream/${listenKey}`)
};

const saveCsvApi = (wtId, source) => {
    return requests.getBlob(`/watch/${wtId}/trade-history/export?source=${source}`)
};

const saveWatchTowerCsvApi = (search, page, size) => {
    return requests.getBlob(`/watch/export?page=${page}&size=${size}${search ? `&${search}` : ''}`)
};

export {
    getAccountListApi,
    keepAliveApi,
    saveCsvApi,
    saveWatchTowerCsvApi,
    getSnapshotListApi,
    saveSnapshotCsvApi,
};
