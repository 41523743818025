import axios from "axios";
import config from "../../config";
import requests from "../http";

const registerApi = data => {
    // const { username, password } = data;
    // return axios.post(`${config.API_ROOT}/auth/register`, {
    //     username,
    //     password,
    // });
    return requests.post(`/auth/register`, data);
};

const loginApi = data =>
    axios.post(`${config.API_ROOT}/user/login`, {
        email: data.username,
        password: data.password
    });

const forgotPasswordApi = data =>
    axios.post(`${config.API_ROOT}/auth/forgot_password`, {
        ...data,
        callback_url: `${window.location.origin}${window.location.pathname}#/auth/reset-password`
    });

const resetPasswordApi = data => {
    const { token, password } = data;
    return axios.post(
        `${config.API_ROOT}/auth/set_new_password`,
        {
            new_password: password
        },
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-MEC-AUTHORIZATION": `Bearer ${token}`,
            }
        }
    );
};
export {
    registerApi,
    loginApi,
    forgotPasswordApi,
    resetPasswordApi
};
