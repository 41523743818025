import Accounts from '../views/Dashboard/Accounts';
import AddNewAccount from "../views/Account/AddNewAccount";
import TelegramSetting from "../views/Setting/TelegramSetting";
import AccountDetails from "../views/Dashboard/AccountDetails";
import ManualTrade from "../views/ManualTrade/ManualTrade";
import UserRegister from "../views/Register/UserRegister";
import ChangePassword from "../views/Account/ChangePassword";
import ManageUser from "../views/ManageUser/ManageUser";
import ManageApiKey from "../views/ManageApiKey/ManageApiKeys";
import ManageApiDetails from "../views/ManageApiKey/ManageApiDetails";
import Snapshot from "../views/Snapshot/Snapshot";

const authRoutes = [
    {
        path: "/dashboard",
        regex: /^\/dashboard$/,
        name: "Dashboard",
        component: Accounts,
        showInSidebar: true,
        profileUpdated: true,
        hasBadge: false,
        badgeField: "dashboard",
        mini: "D",
    },
    // {
    //     path: "/register-page",
    //     regex: /^\/auth\/register-page$/,
    //     name: "Register New User",
    //     mini: "R",
    //     component: UserRegister,
    //     showForAdmin: true,
    //     profileUpdated: false,
    // },
    // {
    //     path: "/manage-user",
    //     regex: /^\/manage-user$/,
    //     name: "Manage User",
    //     mini: "M",
    //     component: ManageUser,
    //     showForAdmin: true,
    //     profileUpdated: false,
    // },
    {
        path: "/snapshot",
        regex: /^\/snapshot$/,
        name: "Daily snapshot",
        mini: "D",
        component: Snapshot,
        showForAdmin: true,
        profileUpdated: false,
    },
    {
        path: "/manage-api",
        regex: /^\/manage-api$/,
        name: "Manage Api",
        mini: "M",
        component: ManageApiKey,
        showForAdmin: true,
        profileUpdated: false,
    },
    {
        path: "/manage-api/:id",
        regex: /^\/manage-api\/.+$/,
        name: "Manage Api Details",
        component: ManageApiDetails,
        showInSidebar: false,
        profileUpdated: false,
    },
    // {
    //     path: "/dashboard/:id/manual-trade",
    //     regex: /^\/dashboard\/.+\/manual-trade$/,
    //     name: "Manual Trade",
    //     component: ManualTrade,
    //     profileUpdated: false,
    //     showInSidebar: false
    // },
    {
        path: "/dashboard/:id",
        regex: /^\/dashboard\/.+$/,
        name: "Account Details",
        component: AccountDetails,
        showInSidebar: false,
        profileUpdated: false,
    },
    {
        path: "/account-new",
        regex: /^\/account-new$/,
        name: "New Account",
        component: AddNewAccount,
        profileUpdated: false,
        showInSidebar: false
    },
    // {
    //     path: "/settings",
    //     regex: /^\/settings$/,
    //     name: "Settings",
    //     component: TelegramSetting,
    //     showInSidebar: true,
    //     profileUpdated: true,
    //     mini: "S",
    // },
    {
        path: "/change-password",
        regex: /^\/change-password$/,
        name: "Change password",
        component: ChangePassword,
        showInSidebar: false,
        profileUpdated: true,
    },
];

export default authRoutes;
