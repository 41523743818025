import React from 'react';
import {withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import Assignment from "@material-ui/icons/Assignment";
// import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import CardBody from "../../components/Card/CardBody";
import Loading from "../../components/Loading/Loading";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    // TablePagination,
    TableRow,
    // TableSortLabel,
    Tooltip
} from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import {cardTitle} from "../../assets/jss/material-dashboard-pro-react";
import SweetAlert from "react-bootstrap-sweetalert";
import notificationService from "../../services/notificationService";
import IntegrationReactSelect from "../../components/CustomSelect/IntegrationReactSelect";
import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    header: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    newBtn: {
        marginTop: '15px',
        marginLeft: 'auto'
    },
    mb20: {
        marginBottom: '20px'
    },
    status: {
        borderRadius: '3px',
        textAlign: 'center',
        padding: '5px 10px',
        color: 'white'
    },
    sentStatus: {
        backgroundColor: '#00acc1'
    },
    closedStatus: {
        backgroundColor: 'gray'
    },
    expiredStatus: {
        backgroundColor: 'red'
    },
    dflex: {
        display: 'flex',
    },
    mr5: {
        marginRight: '5px'
    },
    tableCell: {
        fontSize: '1rem',
        padding: '4px 20px'
    }
};

const ACTION = {
    ADD: "add",
    UPDATE: "update",
    DELETE: "delete"
};

const filterConfig = {
    // matchFrom: 'start'
};

class ManageApiDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            modal: null,
            submittingSend: false,
            isShow: false,
            isCreate: false,
        }
    }

    componentDidMount() {
        if (this.isAdminPermission === false) return;

        const { getListUserByWatchTowerId, getListAllUser } = this.props.manageUserStore;
        getListUserByWatchTowerId(this.requestId);
        getListAllUser();
    }

    componentWillUnmount() {
        this.props.manageUserStore.resetManageApi();
    }

    get requestId() {
        return this.props.match.params.id;
    };

    get isAdminPermission() {
        const { currentUser } = this.props.userStore;
        if (currentUser.role !== 'USER') return true;
        return false;
    }

    toggleModalCreate = () => {
        this.setState({ isShow: !this.state.isShow, isCreate: true });
    };

    toggleModal = () => {
        this.setState({ isShow: !this.state.isShow, isCreate: false });
    };

    handleSetModal = (id, action, form) => {
        switch (action) {
            case ACTION.ADD:
                this.setState({ id: id, modal: ACTION.ADD });
                break;
            case ACTION.UPDATE:
                this.setState({ id: id, modal: ACTION.UPDATE, form });
                break;
            case ACTION.DELETE:
                this.setState({ id: id, modal: ACTION.DELETE });
                break;
            default:
                this.setState({ id: "", modal: null });
                break;
        }
    };

    handleCancelModal = () => {
        this.setState({ modal: null });
    };

    handleDeleteItem = async () => {
        this.setState({ modal: null, submittingSend: true });
        console.log('stateId', this.state.id, 'watchTowerId', this.requestId);
        const payload = {
            'userId': this.state.id,
            'watchTowerId': this.requestId,
        }

        const res = await this.props.manageUserStore.retrieveUserFromWT(payload);
        console.log('handleDeleteItem res', res);
        if (res && res.code === 200) {
            notificationService.success('MSG_SUCCESSFUL');
            this.props.manageUserStore.listUserOfWatchTowerKey = this.props.manageUserStore.listUserOfWatchTowerKey.filter(o => o.id !== this.state.id);
        } else {
            notificationService.error(res.message);
        }
        this.setState({ submittingSend: false });
    };

    renderModalDelete = () => {
        return (
            <SweetAlert
                title=""
                onConfirm={this.handleDeleteItem}
                onCancel={this.handleCancelModal}
                confirmBtnCssClass={
                    this.props.classes.button +
                    " " +
                    this.props.classes.danger
                }
                cancelBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.gray
                }
                confirmBtnText="Yes"
                cancelBtnText="No"
                showCancel
            >
                <p style={styles.titleModal}>
                    Are you sure that you want to{" "}
                    <span
                        style={{
                            color: "#f44336",
                            textDecoration: "underline"
                        }}
                    >
                        remove
                    </span>{" "}
                    this user?
                </p>
            </SweetAlert>
        );
    };

    renderModalUpdate = () => {
        const { classes } = this.props;
        const { assignKeyToUserForm, userList } = this.props.manageUserStore;
        const userListReactSelect = userList.map(item => ({label: item.email, value: item.id}));

        return (
            <SweetAlert
                title={"Add User To This WatchTower"}
                style={{
                    display: "revert",
                    width: '55%',
                    top: '15%', maxHeight: '80%', left: '25%',
                    marginLeft: 'auto !important',
                    marginRight: 'auto !important',
                    marginTop: 'auto !important',
                    overflow: 'inherit'
                }}
                onConfirm={this.handleAssignUser}
                onCancel={this.toggleModal}
                confirmBtnCssClass={classes.button + " " + classes.success}
                cancelBtnCssClass={classes.button + " " + classes.danger}
                confirmBtnText={"Add"}
                cancelBtnText="Cancel"
                showCancel
            >
                <IntegrationReactSelect
                    label={'Select user*'}
                    id="state-select"
                    error={!!assignKeyToUserForm.$('userId').error}
                    helpText={assignKeyToUserForm.$('userId').error || undefined}
                    options={userListReactSelect}
                    value={assignKeyToUserForm.$('userId').value}
                    onChange={assignKeyToUserForm.$('userId').onChange}
                    placeholder={'Select user to add'}
                    filterConfig={filterConfig}/>

            </SweetAlert>
        )
    }

    handleAssignUser = async () => {
        const { assignKeyToUserForm } = this.props.manageUserStore;
        const assignKeyToUserValue = assignKeyToUserForm.values();
        if (!assignKeyToUserForm.isValid) {
            return assignKeyToUserForm.showErrors(true);
        }
        if (!assignKeyToUserValue.userId || !assignKeyToUserValue.userId.value) {
            notificationService.error('MSG_INVALID_INPUT');
            return;
        }
        const payload = {
            'userId': assignKeyToUserValue.userId.value,
            'watchTowerId': this.requestId,
            'email': assignKeyToUserValue.userId.label,
        }

        this.setState({ submittingSend: true });
        const res = await this.props.manageUserStore.assignUserToWT(payload);
        console.log('handleAssignUser req', payload, 'res', res);
        if (res && res.code === 200) {
            notificationService.success('MSG_SUCCESSFUL');
            this.props.manageUserStore.listUserOfWatchTowerKey.push({id: assignKeyToUserValue.userId.value, email: assignKeyToUserValue.userId.label});
        } else {
            notificationService.error(res.message);
        }
        assignKeyToUserForm.clear();
        this.setState({ submittingSend: false, isShow: false, isCreate: false});
    }

    render() {
        const { classes } = this.props;
        const { listUserOfWatchTowerKey, isLoading} = this.props.manageUserStore;

        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon className={classes.header}>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Monitor api account</h4>
                            <div style={{marginLeft: 'auto', marginTop: '15px'}}>
                                <Button color="primary" onClick={this.toggleModalCreate}>Add User</Button>
                            </div>

                        </CardHeader>
                        <CardBody>
                            <div style={{ overflow: 'auto' }}>
                                {isLoading ? <Loading /> : <Table aria-labelledby="tableTitle" padding="default">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="10%" className={classes.tableCell} align={'left'} padding={'default'}>Id</TableCell>
                                            <TableCell width="50%" className={classes.tableCell} align={'left'} padding={'default'}>Email</TableCell>
                                            <TableCell width="40%" className={classes.tableCell} align={'left'} padding={'default'}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {listUserOfWatchTowerKey && listUserOfWatchTowerKey.map(n => {
                                            return (
                                                <React.Fragment key={n.id}>
                                                    <Tooltip title=''>
                                                        <TableRow hover tabIndex={-1}>
                                                            <TableCell className={classes.tableCell}>{n.id}</TableCell>
                                                            <TableCell className={classes.tableCell}>{n.email}</TableCell>
                                                            <TableCell className={classes.tableCell}>
                                                                <div className={classes.dflex}>
                                                                    <Button color={"danger"} size='sm' className={ classes.mr5 } onClick={() => this.handleSetModal(n.id, ACTION.DELETE)}>Remove</Button>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </Tooltip>
                                                </React.Fragment>
                                            );
                                        })}
                                    </TableBody>
                                </Table>}
                            </div>

                            {/*<TablePagination*/}
                            {/*    rowsPerPageOptions={[20, 50, 100]}*/}
                            {/*    component="div"*/}
                            {/*    count={listUserPagination.totalItem}*/}
                            {/*    rowsPerPage={pagination.size}*/}
                            {/*    page={pagination.page}*/}
                            {/*    backIconButtonProps={{*/}
                            {/*        'aria-label': 'Previous Page',*/}
                            {/*    }}*/}
                            {/*    nextIconButtonProps={{*/}
                            {/*        'aria-label': 'Next Page',*/}
                            {/*    }}*/}
                            {/*    onChangePage={this.handleChangePage}*/}
                            {/*    onChangeRowsPerPage={this.handleChangePageSize}*/}
                            {/*/>*/}
                        </CardBody>
                    </Card>
                    {this.state.isShow && this.renderModalUpdate()}
                    {this.state.modal === ACTION.DELETE && this.renderModalDelete()}
                </GridItem>
            </GridContainer>
        )
    }
};

export default withStyles({ ...extendedFormsStyle, ...styles, ...sweetAlertStyle })(inject('manageUserStore', 'userStore')(withRouter(observer(ManageApiDetails))));
