import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import withStyles from "@material-ui/core/styles/withStyles";

// react component for creating dynamic tables
import {
    Checkbox,
    Table,
    TableCell,
    TableRow,
    TableBody, TableHead, TableSortLabel, Tooltip, TablePagination, Divider
} from "@material-ui/core";
import { inject, observer } from 'mobx-react';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data';

// @material-ui/icons
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import CardIcon from "../../components/Card/CardIcon.jsx";
import CardHeader from "../../components/Card/CardHeader.jsx";
import Button from "../../components/CustomButtons/Button.jsx";

import { cardTitle } from "../../assets/jss/material-dashboard-pro-react.jsx";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import Loading from "../../components/Loading/Loading";
import {WifiTethering} from "@material-ui/icons";
import Edit from "@material-ui/icons/Edit"
import notificationService from "../../services/notificationService";
import {
    convertUserSettingListToObj,
    telegramSettings
} from "../../services/utils";
import CustomInput from "../../components/CustomInput/CustomInput";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    header: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    newBtn: {
        marginTop: '15px',
        marginLeft: 'auto'
    },
    mb20: {
        marginBottom: '20px'
    },
    mbt10: {
        marginTop: "10px",
        marginBottom: "10px",
    },
    status: {
        borderRadius: '3px',
        textAlign: 'center',
        padding: '5px 10px',
        color: 'white'
    },
    titleModal: {
        fontWeight: "bold",
        fontSize: "16px"
    },
    sentStatus: {
        backgroundColor: '#00acc1'
    },
    closedStatus: {
        backgroundColor: 'gray'
    },
    expiredStatus: {
        backgroundColor: 'red'
    },
    actionButton: {
        paddingLeft: "10px",
        paddingRight: "10px"
    },
    tableCell: {
        fontSize: '1rem',
        padding: '4px 20px'
    }
};

const rows = [
    { id: 'createdAt', numeric: false, disablePadding: false, label: 'Date' },
    { id: 'accName', numeric: false, disablePadding: false, label: 'Account Name' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'action', numeric: false, disablePadding: false, label: 'Actions' },
];

const ACTION = {
    ADD: "add",
    UPDATE: "update",
    DELETE: "delete"
};

class TelegramSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            modal: null,
            submittingSend: false,
        };
    }

    componentDidMount() {
        if (this.isAdminPermission) this.getUserList();
    }

    handleUpdateTelegramSettings = key => {
        const { currentUser } = this.props.userStore;
        if (currentUser.telegramSettings.includes(key)) {
            currentUser.telegramSettings = currentUser.telegramSettings.filter(item => item !== key);
            return;
        }
        currentUser.telegramSettings = [...currentUser.telegramSettings, key];
    };

    updateTelegramSetting = async () => {
        const { currentUser, accountSettingsForm } = this.props.userStore;
        // console.log('updateTelegramSetting', currentUser.telegramSettings);
        this.setState({ submittingSend: true });
        const res = await this.props.userStore.createRequestUpdateTelegramSetting(convertUserSettingListToObj(currentUser.telegramSettings, accountSettingsForm.values().groupId));
        // console.log('updateTelegramSetting res', res);
        if (res.code === 0) {
            notificationService.success('MSG_SUCCESSFUL');
        } else {
            notificationService.error(res.message);
        }
        this.setState({ submittingSend: false });
    };

    getUserList = () => {
        const { getListUserSubTelegram, pagination } = this.props.userStore;
        const { page, size } = pagination;
        getListUserSubTelegram(page, size);
    }

    handleChangePage = (e, page) => {
        this.props.userStore.changePageOfPagination(page);
        this.getUserList();
    }

    handleChangePageSize = (e) => {
        this.props.userStore.changePageSizeOfPagination(e.target.value);
        this.getUserList();
    }

    get isAdminPermission() {
        const { currentUser } = this.props.userStore;
        if (currentUser.role !== 'USER') return true;
        return false;
    }

    handleSetModal = (id, action, form) => {
        switch (action) {
            case ACTION.ADD:
                this.setState({ id, modal: ACTION.ADD });
                break;
            case ACTION.UPDATE:
                this.setState({ id, modal: ACTION.UPDATE, form });
                break;
            case ACTION.DELETE:
                this.setState({ id, modal: ACTION.DELETE });
                break;
            default:
                this.setState({ id: "", modal: null });
                break;
        }
    };

    handleCancelModal = () => {
        this.setState({ modal: null });
    };

    handleDeleteItem = async () => {
        this.setState({ modal: null, submittingSend: true });
        const res = await this.props.userStore.createRequestChangeStatusTelegramUser(this.state.id);
        console.log('handleDeleteItem res', res);
        if (res.code === 0) {
            notificationService.success('MSG_SUCCESSFUL');
            this.props.userStore.userListPagination.items.forEach((element, index) => {
               if (element.id !== this.state.id) return;

                this.props.userStore.userListPagination.items[index].receiveMsg = !this.props.userStore.userListPagination.items[index].receiveMsg;
            });
        } else {
            notificationService.error(res.message);
        }
        this.setState({ submittingSend: false });
    };

    renderModalDelete = () => {
        return (
            <SweetAlert
                title=""
                onConfirm={this.handleDeleteItem}
                onCancel={this.handleCancelModal}
                confirmBtnCssClass={
                    !this.isAdminPermission
                        ? this.props.classes.button +
                        " " +
                        this.props.classes.disabled
                        : this.props.classes.button +
                        " " +
                        this.props.classes.danger
                }
                cancelBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.gray
                }
                confirmBtnText="Yes"
                cancelBtnText="No"
                showCancel
                disabled={!this.isAdminPermission}
            >
                <p style={styles.titleModal}>
                    Are you sure that you want to{" "}
                    <span
                        style={{
                            color: "#f44336",
                            textDecoration: "underline"
                        }}
                    >
                        change status
                    </span>{" "}
                    this item?
                </p>
            </SweetAlert>
        );
    };

    render() {
        const { classes } = this.props;
        const { currentUser, isLoading, pagination, userListPagination, accountSettingsForm } = this.props.userStore;
        const selectedTelegramSettings = currentUser.telegramSettings ? currentUser.telegramSettings : [];

        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <WifiTethering />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Telegram message type </h4>
                        </CardHeader>
                        <CardBody>
                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem>
                                        <Table style={{ marginTop: "10px" }} aria-labelledby="tableTitle">
                                            <TableBody>
                                                {telegramSettings.map(item => (
                                                    <TableRow key={item.key}>
                                                        <TableCell>
                                                            <Checkbox
                                                                tabIndex={-1}
                                                                onClick={e => this.handleUpdateTelegramSettings(item.key)}
                                                                defaultChecked={
                                                                    selectedTelegramSettings &&
                                                                    selectedTelegramSettings.includes(item.key)
                                                                }
                                                                checkedIcon={
                                                                    <Check className={classes.checkedIcon} />
                                                                }
                                                                icon={
                                                                    <Check className={classes.uncheckedIcon} />
                                                                }
                                                                classes={{
                                                                    checked:
                                                                    classes.checked,
                                                                    root: classes.checkRoot
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>{item.name}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </GridItem>

                                    <GridContainer>
                                        <GridItem xs={6} sm={6} md={6}>
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses
                                                }}
                                                inputProps={accountSettingsForm.$('groupId').bind()}
                                                labelText='Enter telegram groupId'
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={12} sm={4}>
                                    </GridItem>
                                    <GridItem>
                                        <div>
                                            <Button
                                                color="primary"
                                                className={classes.mr10}
                                                onClick={this.updateTelegramSetting}
                                                loading={this.state.submittingSend}
                                                disabled={this.state.submittingSend}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <Divider className={classes.mbt10} />
                            </GridItem>

                            {!this.isAdminPermission ? <div> </div> :
                                <div style={{ overflow: 'auto' }}>
                                    {isLoading ? <Loading /> : <Table aria-labelledby="tableTitle" padding="default">
                                        <TableHead>
                                            <TableRow>
                                                {rows.map(
                                                    row => (
                                                        <TableCell key={row.id}
                                                                   className={classes.tableCell}
                                                                   align={row.numeric ? 'right' : 'left'}
                                                                   padding={row.disablePadding ? 'none' : 'default'}
                                                                   sortDirection={false}
                                                        >
                                                            <TableSortLabel>
                                                                {row.label}
                                                            </TableSortLabel>
                                                        </TableCell>
                                                    ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {userListPagination.items.map(n => {
                                                return (
                                                    <React.Fragment key={n.id}>
                                                        <Tooltip title=''>
                                                            <TableRow hover tabIndex={-1}>
                                                                <TableCell>{moment(n.createdAt).format('DD MMM YYYY')}</TableCell>

                                                                <TableCell className={classes.tableCell}>{n.userName}</TableCell>

                                                                <TableCell className={classes.tableCell}>{n.receiveMsg ? "Active" : "Suspend"}</TableCell>

                                                                 <TableCell className={classes.tableCell}>
                                                                     <Button
                                                                         color={"info"}
                                                                         simple
                                                                         disabled={ !this.isAdminPermission }
                                                                         style={ styles.actionButton }
                                                                         className={ classes.actionButton }
                                                                         onClick={() =>
                                                                             this.handleSetModal(
                                                                                 n.id,
                                                                                 ACTION.DELETE
                                                                             )
                                                                         }
                                                                     >
                                                                         <Edit className={classes.icon}/>
                                                                     </Button>
                                                                 </TableCell>
                                                            </TableRow>
                                                        </Tooltip>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>}
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20, 50, 100]}
                                        component="div"
                                        count={userListPagination.totalItem}
                                        rowsPerPage={pagination.size}
                                        page={pagination.page}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangePageSize}
                                    />
                                </div>
                            }

                        </CardBody>
                    </Card>
                </GridItem>
                {this.state.modal === ACTION.DELETE && this.renderModalDelete()}
            </GridContainer>
        )
    }
}

export default withStyles({ ...extendedFormsStyle, ...styles })(inject('userStore')(observer(TelegramSetting)));
