import { decorate, observable, action } from "mobx";
// import * as _ from 'lodash';
import {
    assignUserToWatchTowerId,
    getAllUser,
    getListManageUser,
    getUserListByWatchTowerId, retrieveUserFromWatchTowerId
} from "../services/domainServices/manageUserDomain.service";
import notificationService from "../services/notificationService";
import AssignKeyToUserForm from "./form/assiginKeyToUser";
import AddUserToGroupForm from "./form/addUserToGroup";

// const initialListUserPagination = {
//     page: 1,
//     items: [],
//     size: 20,
//     totalPage: 0,
//     totalItem: 0
// };
//
// const initialPagination = {
//     size: 20,
//     page: 0,
//     search: ""
// };

class ManageUserStore {
    // listManageUserPagination = _.cloneDeep(initialListUserPagination);
    listManageUser = [];
    userList = [];
    listUserOfWatchTowerKey = [];
    // pagination = _.cloneDeep(initialPagination);

    isLoading = false;

    constructor() {
        this.assignKeyToUserForm = new AssignKeyToUserForm();
        this.addUserToGroupForm = new AddUserToGroupForm();
    }

    // changePageOfPagination = page => {
    //     this.pagination.page = page;
    // };
    //
    // changePageSizeOfPagination = pageSize => {
    //     this.pagination.size = pageSize;
    // };
    //
    // changeSearchPagination = (search) => {
    //     this.pagination.search = search;
    // };

    getListManageUser = () => {
        this.isLoading = true;
        return getListManageUser().then(
            action(res => {
                // console.log('getListUser res', res);
                if (res.code === 200) {
                    this.listManageUser = res.data;
                } else {
                    notificationService.error('COMMON.UNKNOWN_ERROR');
                }

                this.isLoading = false;
            })
        )
    }

    getListAllUser = () => {
        return getAllUser().then(
            action(res => {
                if (res.code === 200) {
                    this.userList = res.data;
                    console.log('getAllUser res', res.data);
                } else {
                    notificationService.error('COMMON.UNKNOWN_ERROR');
                }
            })
        )
    };

    getListUserByWatchTowerId = (watchTowerId) => {
        return getUserListByWatchTowerId(watchTowerId).then(
            action(res => {
                if (res.code === 200) {
                    this.listUserOfWatchTowerKey = res.data;
                } else {
                    notificationService.error('COMMON.UNKNOWN_ERROR');
                }
            })
        )
    };

    assignUserToWT = (payload) => {
        return assignUserToWatchTowerId(payload);
    }

    retrieveUserFromWT = (payload) => {
        return retrieveUserFromWatchTowerId(payload);
    }

    resetManageApi() {
        this.listUserOfWatchTowerKey = [];
    }

    reset() {
        this.listManageUser = [];
        this.userList = [];
    }
};

decorate(ManageUserStore, {
    // auditLogsPagination: observable,
    userList: observable,
    listManageUser: observable,
    listUserOfWatchTowerKey: observable,
    // pagination: observable,
    isLoading: observable,
    // changePageOfPagination: action,
    // changeSearchPagination: action,
    // changePageSizeOfPagination: action,
    getListManageUser: action,
    getListAllUser: action,
    assignUserToWT: action,
    retrieveUserFromWT: action,
});

export default new ManageUserStore();
