import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withRouter, Link } from 'react-router-dom';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data';

// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Popper, Hidden, Paper, Grow, ClickAwayListener, MenuList, MenuItem } from "@material-ui/core";

// @material-ui/icons
import Person from "@material-ui/icons/Person";

// core components
import Button from "../../components/CustomButtons/Button.jsx";

import adminNavbarLinksStyle from "../../assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx";
import { observer, inject } from "mobx-react";

class HeaderLinks extends React.Component {
  state = {
    openPersonal: false,
  };
  componentDidMount() {
    // this.props.userNotificationStore.getNavbarUserNotification();
  }

  handleClickPersonal = () => {
    this.setState({ openPersonal: !this.state.openPersonal });
  };
  handleClosePersonal = () => {
    this.setState({ openPersonal: false });
  };

  navigateToChangePassword = () => {
    this.props.history.push('/change-password');
    this.handleClosePersonal();
  }

  logout = () => {
    this.props.userStore.logout();
    this.handleClosePersonal();
  }

  render() {
    const { classes } = this.props;
    const { open, openPersonal, openHelp } = this.state;
    const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover);
    const managerClasses = classNames({
      [classes.managerClasses]: true
    });

    return (
      <div>
        <div className={managerClasses}>
          <Button
            color="transparent"
            justIcon
            aria-label="Personal"
            aria-owns={open ? "menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleClickPersonal}
            className={classes.buttonLink}
            muiClasses={{
              label: ""
            }}
            buttonRef={node => {
              this.anchorPersonalEl = node;
            }}
          >
            <Person
              className={classes.headerLinksSvg + " " + classes.links}
            />
            <Hidden mdUp implementation="css">
              <span onClick={this.handleClickPersonal} className={classes.linkText}>
                {"Personal"}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={openPersonal}
            anchorEl={this.anchorPersonalEl}
            transition
            placement="bottom-start"
            modifiers={{
              preventOverflow: {
                enabled: true,
                boundariesElement: 'scrollParent',
              },
            }}
            className={classNames({
              [classes.popperClose]: !openPersonal,
              [classes.pooperResponsive]: true,
              [classes.pooperNav]: true
            })}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <div
                    className={classes.notificationsWrapper}
                  >
                    <ClickAwayListener onClickAway={this.handleClosePersonal}>
                      <MenuList role="menu">
                        <MenuItem
                            onClick={this.navigateToChangePassword}
                            className={dropdownItem}
                        >
                          {"Change password"}
                        </MenuItem>
                        <MenuItem
                          onClick={this.logout}
                          className={dropdownItem}
                        >
                          {"Logout"}
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </div>
                </Paper>
              </Grow>
            )}
          </Popper>

        </div>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(adminNavbarLinksStyle)(inject('userStore')(withRouter(observer(HeaderLinks))));
