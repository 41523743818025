import {
    assignUserToWatchTowerIdApi,
    getAllUserListApi,
    getListManageUserApi,
    getUserListByWatchTowerIdApi, retrieveUserFromWatchTowerIdApi
} from "../dataServices/manageUserData.service";

const getListManageUser = () => {
    return getListManageUserApi();
};

const getAllUser = () => {
    return getAllUserListApi();
}

const getUserListByWatchTowerId = (id) => {
    return getUserListByWatchTowerIdApi(id);
}

const assignUserToWatchTowerId = (data) => {
    return assignUserToWatchTowerIdApi(data);
}

const retrieveUserFromWatchTowerId = (data) => {
    return retrieveUserFromWatchTowerIdApi(data);
}

export {
    getListManageUser,
    getAllUser,
    getUserListByWatchTowerId,
    assignUserToWatchTowerId,
    retrieveUserFromWatchTowerId,
};
