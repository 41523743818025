import { Form } from "mobx-react-form";
import plugins from "./plugins";

export default class UserRegisterForm extends Form {
    plugins() {
        return plugins;
    }

    setup() {
        return {
            fields: [
                {
                    name: "username",
                    placeholder: "Enter email",
                    rules: "required|email",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "password",
                    placeholder: "Enter your password",
                    rules: "required|password",
                    type: "password",
                    hooks: {
                        onChange: field => {
                            field.validate();
                            this.$('confirmPassword').validate();
                            this.$('confirmPassword').showErrors();
                        }
                    }
                },
                {
                    name: "confirmPassword",
                    placeholder: "Enter confirm password",
                    type: "password",
                    rules: "required|same:password",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "groupId",
                    placeholder: "Enter groupId",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                // {
                //     name: "agreeTerm",
                //     type: "checkbox",
                //     hooks: {
                //         onChange: field => {
                //             field.validate();
                //         }
                //     }
                // }
            ]
        };
    }
}
