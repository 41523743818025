import React from 'react';
import Loading from "../../../components/Loading/Loading";
import {Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip} from "@material-ui/core";
import * as moment from "moment-timezone/builds/moment-timezone-with-data";
import { withRouter } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";
import extendedFormsStyle from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import {cardTitle} from "../../../assets/jss/material-dashboard-pro-react";
import GridItem from "../../../components/Grid/GridItem";
import IntegrationReactSelect from "../../../components/CustomSelect/IntegrationReactSelect";
import GridContainer from "../../../components/Grid/GridContainer";
import Button from "../../../components/CustomButtons/Button";
import {SOURCE_EXCHANGE} from "../../../services/utils";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    header: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    newBtn: {
        marginTop: '15px',
        marginLeft: 'auto'
    },
    label: {
        fontWeight: 'bold',
        marginTop: '10px',
        marginBottom: '10px',
    },
    mbt10: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    mb20: {
        marginBottom: '20px'
    },
    status: {
        borderRadius: '3px',
        textAlign: 'center',
        padding: '5px 10px',
        color: 'white'
    },
    sentStatus: {
        backgroundColor: '#00acc1'
    },
    closedStatus: {
        backgroundColor: 'gray'
    },
    expiredStatus: {
        backgroundColor: 'red'
    },
    tableCell: {
        fontSize: '1rem',
        padding: '4px 20px'
    }
};

const rows = [
    { id: 'id', numeric: false, disablePadding: false, label: 'No.', disableSort: true, isNumber: true },
    { id: 'symbol', numeric: false, disablePadding: false, label: 'Symbol', disableSort: false, isNumber: false },
    { id: 'price', numeric: false, disablePadding: false, label: 'Price', disableSort: false, isNumber: true },
    { id: 'origQty', numeric: false, disablePadding: false, label: 'Quantity', disableSort: false, isNumber: true },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status', disableSort: false, isNumber: false },
    { id: 'side', numeric: false, disablePadding: false, label: 'Side', disableSort: false, isNumber: false },
    { id: 'type', numeric: false, disablePadding: false, label: 'Type', disableSort: false, isNumber: false },
    { id: 'updateTime', numeric: false, disablePadding: false, label: 'Updated Time', disableSort: true, isNumber: false },
    { id: 'clientOrderId', numeric: false, disablePadding: false, label: 'Client Order Id', disableSort: true, isNumber: false },
    // { id: 'action', numeric: false, disablePadding: false, label: 'Action' },
];

const filterConfig = {
    // matchFrom: 'start'
};

class TransactionHistory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            submittingSend: false,
        }
    }

    componentDidMount() {
        // this.getAccountTradeHistory();
    }

    getAccountTradeHistory = () => {
        const { searchFieldForm } = this.props.dashboardStore;
        const pair = searchFieldForm.values().pair;
        console.log('getAccountTradeHistory symbol', pair);
        this.props.userStore.getTradeHistory(this.requestId, pair).then(res => {});
    }

    get requestId() {
        return this.props.match.params.id;
    };

    handleChangePair = (e) => {
        this.props.dashboardStore.searchFieldForm.$('pair').onChange(e.value);
        this.getAccountTradeHistory();
    }

    handleExportCsv = () => {
        this.setState({ submittingSend: true });
        this.props.dashboardStore.downloadHistory(this.requestId, this.props.userStore.sourceExchange, this.props.userStore.addNewAccountForm.values().description, false);
        this.setState({ submittingSend: false });
    }

    handleSort = (sort, isNumber) => () => {
        this.props.userStore.changeSortOfPaginationTransactionHistory(sort, isNumber);
    }

    render() {
        const { classes } = this.props;
        const { isTradeHistoryLoading, accountTradeHistoryList, symbolSupportList, sourceExchange, symbolSupportListOkxSpot, paginationTransactionHistory } = this.props.userStore;
        const { searchFieldForm } = this.props.dashboardStore;
        const symbolSupportReactSelect = sourceExchange === SOURCE_EXCHANGE['BINANCE'] ? symbolSupportList.map(item => ({label: item.name, value: item.code})) : symbolSupportListOkxSpot.map(item => ({label: item.name, value: item.code}));

        return (
            <GridItem>
                <GridContainer>
                    <GridItem xs={3}>
                        <IntegrationReactSelect
                            label={'Select pair*'}
                            id="state-select"
                            error={!!searchFieldForm.$('pair').error}
                            helpText={searchFieldForm.$('pair').error || undefined}
                            options={symbolSupportReactSelect}
                            value={searchFieldForm.$('pair').value}
                            onChange={this.handleChangePair}
                            placeholder={'Select pair'}
                            filterConfig={filterConfig}/>
                    </GridItem>
                    <GridItem xs={6}></GridItem>
                    <GridItem xs={3}>
                        <Button fullWidth color="info" onClick={this.handleExportCsv} loading={this.state.submittingSend} disabled={this.state.submittingSend}>
                            Download all trade history
                        </Button>
                    </GridItem>
                </GridContainer>


                <div style={{ overflow: 'auto' }}>
                    {isTradeHistoryLoading ? <Loading /> :
                        <Table aria-labelledby="tableTitle" padding="default">
                        <TableHead>
                            <TableRow>
                                {rows.map(
                                    row => (
                                        <TableCell key={row.id}
                                                   className={classes.tableCell}
                                                   align={row.numeric ? 'right' : 'left'}
                                                   padding={row.disablePadding ? 'none' : 'default'}
                                                   sortDirection={paginationTransactionHistory.sort === row.id ? paginationTransactionHistory.order : false}
                                        >
                                            {!row.disableSort ? <Tooltip
                                                    title="Sort"
                                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                                    enterDelay={300}
                                                >
                                                    <TableSortLabel
                                                        active={paginationTransactionHistory.sort === row.id}
                                                        direction={paginationTransactionHistory.order}
                                                        onClick={this.handleSort(row.id, row.isNumber)}
                                                    >
                                                        {row.label}
                                                    </TableSortLabel>
                                                </Tooltip> :
                                                <TableSortLabel>{row.label}</TableSortLabel>
                                            }
                                        </TableCell>
                                    ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {accountTradeHistoryList && accountTradeHistoryList.map((n, index) => {
                                return (
                                    <React.Fragment key={index++}>
                                        <Tooltip title=''>
                                            <TableRow hover tabIndex={-1}>
                                                <TableCell className={classes.tableCell}>{index + 1}</TableCell>
                                                <TableCell className={classes.tableCell}>{n.symbol}</TableCell>
                                                <TableCell className={classes.tableCell}>{n.price}</TableCell>
                                                <TableCell className={classes.tableCell}>{(n.executedQty * 1).toString()}</TableCell>
                                                <TableCell className={classes.tableCell}>{n.status}</TableCell>
                                                <TableCell className={classes.tableCell}>{n.side}</TableCell>
                                                <TableCell className={classes.tableCell}>{n.type}</TableCell>
                                                <TableCell className={classes.tableCell}>{moment(n.updateTime).format('DD MMM YYYY HH:mm:ss')}</TableCell>
                                                <TableCell className={classes.tableCell}>{n.clientOrderId}</TableCell>

                                            </TableRow>
                                        </Tooltip>
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>}
                </div>
            </GridItem>
        )
    }
}

export default withStyles({ ...extendedFormsStyle, ...styles })(inject('commonStore', 'userStore', 'dashboardStore')(withRouter(observer(TransactionHistory))));
