import { Form } from "mobx-react-form";
import plugins from "./plugins";

export default class ResetPasswordForm extends Form {
    plugins() {
        return plugins;
    }

    setup() {
        return {
            fields: [
                {
                    name: "password",
                    placeholder: "Enter your new password",
                    rules: "required|password",
                    type: "password",
                    hooks: {
                        onChange: field => {
                            field.validate();
                            this.$('confirmPassword').validate();
                            this.$('confirmPassword').showErrors();
                        }
                    }
                },
                {
                    name: "confirmPassword",
                    placeholder: "Enter confirm new password",
                    type: "password",
                    rules: "required|same:password",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                }
            ]
        };
    }
}
