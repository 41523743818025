import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import CustomInput from "../components/CustomInput/CustomInput.jsx";
import Button from "../components/CustomButtons/Button.jsx";
import Card from "../components/Card/Card.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardHeader from "../components/Card/CardHeader.jsx";
import CardFooter from "../components/Card/CardFooter.jsx";

import loginPageStyle from "../assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import { inject, observer } from "mobx-react";
import notificationService from "../services/notificationService";
import {Lock} from "@material-ui/icons";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden"
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  login = (e) => {
    e.preventDefault();
    const { loginForm, login } = this.props.authStore;
    if (!loginForm.isValid) {
      return loginForm.showErrors(true);
    }
    loginForm.submit({
      onSuccess: (form) => {
        const data = form.values();
        login(data).then((res) => {
          // console.log('Login res', res);
          if (res.code === 200) {
            notificationService.success('LOGIN.SUCCESS');
          } else {
            notificationService.error('MSG_LOGIN_FAILED');
          }
        }).catch((res) => {
          console.log('login error res', res);
          notificationService.error('COMMON.UNKNOWN_ERROR');
        });
      },
      onError: (form) => {
        // eslint-disable-next-line no-console
        console.log("All form errors", form.errors());
      }
    })
  }

  render() {
    const { classes } = this.props;
    const { loginForm } = this.props.authStore;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={this.login}>
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="primary"
                >
                  <h4 className={classes.cardTitle}>Log in</h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Face className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      ...loginForm.$('username').bind()
                    }}
                    error={!!loginForm.$('username').error}
                    helpText={loginForm.$('username').error || undefined}
                    labelText='Username'
                  />
                  <CustomInput
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Lock className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Lock>
                        </InputAdornment>
                      ),
                      ...loginForm.$('password').bind()
                    }}
                    error={!!loginForm.$('password').error}
                    helpText={loginForm.$('password').error || undefined}
                    labelText="Password"
                  />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControlLabel
                        classes={{
                          root: classes.checkboxLabelControl,
                          label: classes.checkboxLabel
                        }}
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={loginForm.$('rememberMe').onChange}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        label="Remember me"
                      />
                    </GridItem>
                    {/*<GridItem xs={12} sm={12} md={6} className={classes.textRight}>*/}
                    {/*  <Link to="/auth/forgot-password">Forgot password ?</Link>*/}
                    {/*</GridItem>*/}
                  </GridContainer>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <div>
                    <Button color="primary" simple size="lg" block type="submit"> Let's Go </Button>
                    {/*<div className={classes.bottomText}>First Time User? <Link className={classes.registerLink} to="/auth/register-page">REGISTER HERE</Link> </div>*/}
                  </div>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(inject('authStore')(observer(LoginPage)));
