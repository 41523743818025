import React, {useEffect, useMemo, useState} from "react";
import { Link, withRouter } from 'react-router-dom';

// react component for creating dynamic tables
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TableSortLabel,
    Tooltip,
    MenuItem, Select,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import CardIcon from "../../components/Card/CardIcon.jsx";
import CardHeader from "../../components/Card/CardHeader.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";

import { cardTitle } from "../../assets/jss/material-dashboard-pro-react.jsx";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import Loading from "../../components/Loading/Loading";
import {convertFundIdToText, reduceMaskText, toMoneyValue, upperFirstLetter} from "../../services/utils";
import {LibraryBooks} from "@material-ui/icons";
import useWebSocket, {ReadyState} from "react-use-websocket";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "10px",
        marginBottom: "0px"
    },
    header: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    newBtn: {
        marginTop: '15px',
        marginLeft: 'auto'
    },
    status: {
        borderRadius: '3px',
        textAlign: 'center',
        padding: '5px 10px',
        color: 'white'
    },
    sentStatus: {
        backgroundColor: '#00acc1'
    },
    closedStatus: {
        backgroundColor: 'gray'
    },
    expiredStatus: {
        backgroundColor: 'red'
    },
    tableCell: {
        fontSize: '1rem',
        padding: '4px 20px'
    },
    tablePaginationToolbar: {
        height: '48px',
        minHeight: '48px',
        paddingRight: '2px',
    },
};

const rows = [
    { id: 'id', numeric: false, disablePadding: false, label: 'Id', disableSort: false, isNumber: true },
    { id: 'description', numeric: false, disablePadding: false, label: 'Description', disableSort: false },
    { id: 'initialCap', numeric: true, disablePadding: false, label: 'Initial Cap', disableSort: false },
    { id: 'balance', numeric: true, disablePadding: false, label: 'Spot Balance (USDT)', disableSort: false, isNumber: true },
    // { id: 'pnlusdt', numeric: false, disablePadding: false, label: 'PnL (%)', disableSort: false },
    { id: 'fbalance', numeric: true, disablePadding: false, label: 'Future Balance (USDT)', disableSort: false, isNumber: true },
    { id: 'sumBalance', numeric: true, disablePadding: false, label: 'Sum Balance (USDT)', disableSort: false, isNumber: true },
    // { id: 'pnl', numeric: true, disablePadding: false, label: 'Sum PnL (USDT / %)', disableSort: false, hasPercent: true, isNumber: true},
    { id: 'sumPnlusdt', numeric: true, disablePadding: false, label: 'Sum PnL (USDT)', disableSort: false, hasPercent: false, isNumber: true},
    // { id: 'fpnl', numeric: false, disablePadding: false, label: 'Future PnL (Toggle)', disableSort: true , hasPercent: true},
    // { id: 'fpnlusdt', numeric: false, disablePadding: false, label: 'Future PnL (%)', disableSort: false },
    { id: 'ftotalUnrealizedProfit', numeric: true, disablePadding: false, label: 'Total Unrealized Profit', disableSort: false, isNumber: true },
    { id: 'totalOpenOrder', numeric: false, disablePadding: false, label: 'Total Spot Orders', disableSort: false, isNumber: true },
    { id: 'fopenPosition', numeric: false, disablePadding: false, label: 'Future Total Positions', disableSort: false, isNumber: true },
    { id: 'fopenOrder', numeric: false, disablePadding: false, label: 'Future Total Orders', disableSort: false, isNumber: true },
    { id: 'exchange', numeric: false, disablePadding: false, label: 'Account', disableSort: false },
    { id: 'fund', numeric: false, disablePadding: false, label: 'Fund', disableSort: false },
    { id: 'address', numeric: false, disablePadding: false, label: 'Address', disableSort: true },
    // { id: 'action', numeric: false, disablePadding: false, label: 'Actions', disableSort: true },
];

const searchFields = [
    {
        code: 'apiKey',
        name: 'Address'
    },
    {
        code: 'description',
        name: 'Description'
    },
]

class Accounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPnlPercentSpot: false,
            showPnlPercentFuture: false,
            submittingSend: false,
        }
    }

    componentDidMount() {
        this.props.dashboardStore.pagination.params = '';
        this.getAccountList();
    }

    getAccountList = () => {
        const { getAccountsList, pagination, searchFieldForm } = this.props.dashboardStore;
        const { page, size, params } = pagination;
        const paramRequest = params ? searchFieldForm.values().search + '=' + params : '';
        console.log('getAccountList search', paramRequest);
        getAccountsList(paramRequest, page, size);
    }

    handleChangePage = (e, page) => {
        this.props.dashboardStore.changePageOfPagination(page);
        this.getAccountList();
    }

    handleChangePageSize = (e) => {
        this.props.dashboardStore.changePageSizeOfPagination(e.target.value);
        this.getAccountList();
    }

    setCurrentAccount = acc => () => {
        if (!acc.id) return;
        if (acc.exchange === 'OKX') {
            this.props.userStore.isOkxExchange = true;
            this.props.dashboardStore.updateInitPairFilter('');
        } else if (acc.exchange === 'BYBIT') {
            this.props.userStore.isBybitExchange = true;
        } else {
            this.props.userStore.isOkxExchange = false;
            this.props.userStore.isBybitExchange = false;
            this.props.dashboardStore.updateInitPairFilter('BTCUSDT');
        }

        this.props.history.push(`/dashboard/${acc.id}`);
    }

    onSearchChange = (event) => {
        this.props.dashboardStore.changeAccNamePagination(event.target.value);
    }

    styleStatusColor = (id, pnl) => {
        if (!id) return {background: "#FFEF88", cursor: 'not-allowed'}
        if (pnl < 0) return {background: "rgba(246, 70, 93, 0.15)", cursor: 'pointer'};
        return {background: "rgba(14, 203, 129, 0.15)", cursor: 'pointer'};
    }

    handleSort = (sort, isNumber) => () => {
        this.props.dashboardStore.changeSortOfPagination(sort, isNumber);
        // this.getListingsList(); // sort local
    }

    handleShowPercent = id => () => {
        if (id === 'pnl') this.setState({showPnlPercentSpot: !this.state.showPnlPercentSpot});
        else if (id === 'fpnl') this.setState({showPnlPercentFuture: !this.state.showPnlPercentFuture});
    }

    handleExportWatchTower = () => {
        this.setState({ submittingSend: true });
        const { downloadWatchTower, pagination, searchFieldForm } = this.props.dashboardStore;
        const { page, size, params } = pagination;
        const paramRequest = params ? searchFieldForm.values().search + '=' + params : '';
        downloadWatchTower(paramRequest, page, 1000);
        this.setState({ submittingSend: false });
    }

    render() {
        const { classes } = this.props;
        const { accountsPagination, pagination, isLoading, searchFieldForm } = this.props.dashboardStore;
        // const { listenKey, isWsSubscribed } = this.props.userStore;
        // const timeZone = 'GMT+7';

        // const WebSocket = ({accountList}) => {
        //     const [accountInfoList, setAccountInfo] = useState({ ...accountList});
        //
        //     const updateAccountInfo = (accountInfo, update) => {
        //         console.log('accountInfo ', accountList, update.data)
        //         // const nextAccountInfo = { ...accountInfo };
        //
        //         accountList = accountList.map(account => {
        //             if (account.id === update.data.id) {
        //                 console.log('found account ', account);
        //                 account.balance = update.data.balance;
        //                 account.totalOpenOrder = update.data.totalOpenOrder;
        //                 console.log('update account done', account);
        //             }
        //
        //             return account;
        //         });
        //         console.log('after', accountList);
        //         //Use update data to modify nextAccountInfo;
        //         // return nextAccountInfo;
        //     };
        //
        //     //Public API that will echo messages sent to it back to the client
        //     const STATIC_OPTIONS = useMemo(() => ({
        //         onOpen: () => console.log('WebSocket connection opened.'),
        //         onClose: (e) => console.log('WebSocket connection closed.', e),
        //         onError: (e) => console.log('WebSocket connection error.', e),
        //         share: true,
        //     }), []); //id will never change since it is used as part of the key -- if it changes, a new Account component is mounted
        //     const {sendJsonMessage, lastMessage, readyState, getWebSocket} = useWebSocket(config.WS_API, STATIC_OPTIONS);
        //
        //     useEffect(() => {
        //         console.log('test', lastMessage);
        //         if (lastMessage !== null && lastMessage.data !== null) {
        //             // setAccountInfo(prev => updateAccountInfo(prev, lastMessage));
        //             console.log('accountInfo ', lastMessage.data, accountInfoList, accountList)
        //             // const nextAccountInfo = { ...accountInfo };
        //
        //             accountList = accountList.map(account => {
        //                 console.log('account test', account);
        //                 if (account.id === lastMessage.data.id) {
        //                     console.log('found account ', account);
        //                     account.balance = lastMessage.data.balance;
        //                     account.totalOpenOrder = lastMessage.data.totalOpenOrder;
        //                     console.log('update account done', account);
        //                 }
        //
        //                 return account;
        //             });
        //             console.log('list after', accountList);
        //         }
        //
        //         if (readyState !== ReadyState.OPEN || isLoading || isWsSubscribed) return;
        //
        //         const subscribeMessage = {
        //             method: "SUBSCRIBE",
        //             id: 1,
        //             params: [listenKey]
        //         };
        //         console.log('sending init subscribe', subscribeMessage);
        //         sendJsonMessage(subscribeMessage);
        //         this.props.userStore.isWsSubscribed = true;
        //     }, [lastMessage, sendJsonMessage, readyState, setAccountInfo]);
        //
        //     return (
        //         accountList && accountList.map(n => {
        //             return (
        //                 <React.Fragment key={n.id}>
        //                     <Tooltip title=''>
        //                         <TableRow hover tabIndex={-1} onClick={this.setCurrentAccount(n)} style={this.styleStatusColor(n.pnl)}>
        //                             <TableCell className={classes.tableCell}>{upperFirstLetter(n.exchange)}</TableCell>
        //
        //                             <TableCell className={classes.tableCell}>{reduceMaskText(n.apiKey)}</TableCell>
        //
        //                             <TableCell className={classes.tableCell}>{n.description}</TableCell>
        //
        //                             <TableCell className={classes.tableCell}>{n.initialCap}</TableCell>
        //
        //                             <TableCell className={classes.tableCell}>{n.balance}</TableCell>
        //
        //                             <TableCell className={classes.tableCell}>{n.pnl}</TableCell>
        //
        //                             <TableCell className={classes.tableCell}>{n.totalOpenOrder}</TableCell>
        //
        //                             {/*<TableCell className={classes.tableCell}>{moment(n.createdAt).format('DD MMM YYYY')}</TableCell>*/}
        //
        //                             <TableCell className={classes.tableCell}>view</TableCell>
        //
        //                         </TableRow>
        //                     </Tooltip>
        //                 </React.Fragment>
        //             );
        //         })
        //     );
        // };

        // const Accounts = ({ accountList }) => {
        //     return (
        //         accountList.map(n => {
        //             return (
        //                 <React.Fragment key={n.id}>
        //                     <Tooltip title=''>
        //                         <Account
        //                             key={`account-${n.id}`} //Assumes unique account ids
        //                             account={n}
        //                             id={n.id}
        //                         />
        //                     </Tooltip>
        //                 </React.Fragment>
        //             )
        //         }
        //     ));
        // }
        //
        // const updateAccountInfo = (accountInfo, update) => {
        //     const nextAccountInfo = { ...accountInfo };
        //
        //     //Use update data to modify nextAccountInfo;
        //
        //     return nextAccountInfo;
        // };
        //
        // const Account = ({ account, id }) => {
        //     const [accountInfo, setAccountInfo] = useState({ ...account});
        //     const STATIC_OPTIONS = useMemo(() => ({
        //         onOpen: () => console.log('WebSocket connection opened.'),
        //         onClose: () => console.log('WebSocket connection closed.'),
        //         share: true,
        //         filter: message => message.id === id
        //     }), [id]); //id will never change since it is used as part of the key -- if it changes, a new Account component is mounted
        //     const {sendJsonMessage, lastMessage, readyState, getWebSocket} = useWebSocket(config.WS_API, STATIC_OPTIONS);
        //
        //     useEffect(() => {
        //         console.log('test', lastMessage);
        //         if (lastMessage !== null) {
        //             setAccountInfo(prev => updateAccountInfo(prev, lastMessage));
        //         }
        //     }, [lastMessage, sendJsonMessage, setAccountInfo]); //sendMessage and setAccountInfo are stable, and so this effect is only called when lastMessage changes
        //
        //     useEffect(() => {
        //         if (readyState !== ReadyState.OPEN || isLoading || isWsSubscribed) return;
        //
        //         const subscribeMessage = {
        //             method: "SUBSCRIBE",
        //             id: 1,
        //             params: [listenKey]
        //         };
        //         console.log('sending init subscribe', subscribeMessage);
        //         sendJsonMessage(subscribeMessage);
        //         this.props.userStore.isWsSubscribed = true;
        //     }, [readyState])
        //
        //     return (
        //         <AccountInfo
        //             info={accountInfo}
        //         />
        //     );
        // };
        //
        // const AccountInfo = ({ info }) => {
        //     return (
        //         <TableRow hover tabIndex={-1} onClick={this.setCurrentAccount(info)} style={this.styleStatusColor(info.pnl)}>
        //             <TableCell className={classes.tableCell}>{upperFirstLetter(info.exchange)}</TableCell>
        //
        //             <TableCell className={classes.tableCell}>{reduceMaskText(info.apiKey)}</TableCell>
        //
        //             <TableCell className={classes.tableCell}>{info.description}</TableCell>
        //
        //             <TableCell className={classes.tableCell}>{info.initialCap}</TableCell>
        //
        //             <TableCell className={classes.tableCell}>{info.balance}</TableCell>
        //
        //             <TableCell className={classes.tableCell}>{info.pnl}</TableCell>
        //
        //             <TableCell className={classes.tableCell}>{info.totalOpenOrder}</TableCell>
        //
        //             {/*<TableCell className={classes.tableCell}>{moment(n.createdAt).format('DD MMM YYYY')}</TableCell>*/}
        //
        //             <TableCell className={classes.tableCell}>view</TableCell>
        //
        //         </TableRow>
        //     );
        // };

        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon className={classes.header}>
                            <CardIcon color="primary">
                                <LibraryBooks />
                            </CardIcon>
                            <h3 className={classes.cardIconTitle}>Watch Tower</h3>
                            <div style={{marginLeft: 'auto'}}>
                                {/*<FormControl className={classes.selectFormControl}>*/}
                                    <Select MenuProps={{className: classes.selectMenu}}
                                            classes={{select: classes.select}}
                                            inputProps={{name: "simpleSelect", id: "simple-select"}}
                                            value={searchFieldForm.$('search').value}
                                            onChange={searchFieldForm.$('search').onChange}
                                            style={{width: '120px'}}
                                    >
                                        {searchFields.map(item =>
                                            <MenuItem classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                                      value={item.code}
                                                      key={item.code}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        )}
                                    </Select>
                                    <CustomInput
                                        labelText='Search'
                                        formControlProps={{
                                            fullWidth: false
                                        }}
                                        inputProps={{
                                            onChange: this.onSearchChange,
                                            onKeyUp: (e) => e.key === 'Enter' && this.getAccountList()
                                        }}
                                    />
                                {/*</FormControl>*/}
                                {/*<CustomInput*/}
                                {/*    labelText='Search'*/}
                                {/*    helpText={'Search by address / description'}*/}
                                {/*    formControlProps={{*/}
                                {/*        fullWidth: false*/}
                                {/*    }}*/}
                                {/*    inputProps={{*/}
                                {/*        onChange: this.onSearchChange,*/}
                                {/*        onKeyUp: (e) => e.key === 'Enter' && this.getAccountList()*/}
                                {/*    }}*/}
                                {/*/>*/}
                                <Link to={'/account-new'} style={{textDecoration: "none", marginLeft: "10px"}}>
                                    <Button color="primary">Add New</Button>
                                </Link>
                                <Button color="facebook" onClick={this.handleExportWatchTower} loading={this.state.submittingSend} disabled={this.state.submittingSend}>
                                    Export
                                </Button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div style={{ overflow: 'auto', height: '78vh' }}>
                                {isLoading ? <Loading /> : <Table aria-labelledby="tableTitle" padding="default">
                                    <TableHead style={{ backgroundColor: '#fff', position: "sticky", top: 0 }}>
                                        <TableRow>
                                            {rows.map(
                                                row => (
                                                    <TableCell key={row.id}
                                                        className={classes.tableCell}
                                                        align={row.numeric ? 'right' : 'left'}
                                                        padding={row.disablePadding ? 'none' : 'default'}
                                                        sortDirection={pagination.sort === row.id ? pagination.order : false}
                                                    >
                                                        {!row.disableSort ? <Tooltip
                                                            title="Sort"
                                                            placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                                            enterDelay={300}
                                                        >
                                                        <TableSortLabel
                                                            active={pagination.sort === row.id}
                                                            direction={pagination.order}
                                                            onClick={this.handleSort(row.id, row.isNumber)}
                                                        >
                                                            {row.label}
                                                        </TableSortLabel>
                                                        </Tooltip> : row.hasPercent ? <TableSortLabel onClick={this.handleShowPercent(row.id)}>{row.label}</TableSortLabel> : row.label}
                                                    </TableCell>
                                                ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/*{accountsPagination && accountsPagination.items &&*/}
                                        {/*    <TableContent classes={classes} history={this.props.history} listenKey={listenKey} isFeedKilled={false} dataList={accountsPagination.items}></TableContent>*/}
                                        {/*    <Accounts accountList={accountsPagination.items}></Accounts>*/}
                                        {/*}*/}

                                        {/*{accountsPagination && accountsPagination.items && <WebSocket accountList={accountsPagination.items}></WebSocket>}*/}

                                        {accountsPagination && accountsPagination.items && accountsPagination.items.map((n, index) => {
                                            return (
                                                <React.Fragment key={index++}>
                                                    <Tooltip title=''>
                                                        <TableRow hover tabIndex={-1} onClick={this.setCurrentAccount(n)} style={this.styleStatusColor(n.id, n.sumPnlusdt)}>
                                                            <TableCell className={classes.tableCell}>{n.id}</TableCell>
                                                            <TableCell className={classes.tableCell}>{n.description}</TableCell>
                                                            <TableCell align={"right"} className={classes.tableCell}>{toMoneyValue(n.initialCap)}</TableCell>

                                                            <TableCell align={"right"} className={classes.tableCell}>{toMoneyValue(n.balance)}</TableCell>
                                                            <TableCell align={"right"} className={classes.tableCell}>{toMoneyValue(n.fbalance)}</TableCell>

                                                            <TableCell align={"right"} className={classes.tableCell}>{toMoneyValue(n.sumBalance)}</TableCell>

                                                            {/*<TableCell className={classes.tableCell}>{this.state.showPnlPercentSpot ? (toMoneyValue(n.sumPnl) + " %") : (toMoneyValue(n.sumPnlusdt) + " USDT")}</TableCell>*/}
                                                            {/*<TableCell align={"right"} className={classes.tableCell}>{this.state.showPnlPercentSpot ? (toMoneyValue(n.sumPnl)) : (toMoneyValue(n.sumPnlusdt) )}</TableCell>*/}
                                                            <TableCell align={"right"} className={classes.tableCell}>{(toMoneyValue(n.sumPnlusdt) )}</TableCell>

                                                            {/*<TableCell className={classes.tableCell}>{n.pnl}</TableCell>*/}

                                                            <TableCell align={"right"} className={classes.tableCell}>{toMoneyValue(n.ftotalUnrealizedProfit)}</TableCell>

                                                            {/*<TableCell className={classes.tableCell}>{this.state.showPnlPercentFuture ? ((n.fpnl ? n.fpnl : 0) + " %") : ((n.fpnlusdt ? n.fpnlusdt : 0) + " USDT")}</TableCell>*/}
                                                            {/*<TableCell className={classes.tableCell}>{n.fpnl}</TableCell>*/}
                                                            <TableCell className={classes.tableCell} >{n.totalOpenOrder}</TableCell>
                                                            <TableCell className={classes.tableCell} >{n.fopenPosition}</TableCell>
                                                            <TableCell className={classes.tableCell} >{n.fopenOrder}</TableCell>

                                                            <TableCell className={classes.tableCell}>{upperFirstLetter(n.exchange)}</TableCell>
                                                            <TableCell className={classes.tableCell}>{convertFundIdToText(n.description, n.fundType)}</TableCell>

                                                            <TableCell className={classes.tableCell}>{reduceMaskText(n.apiKey)}</TableCell>

                                                            {/*<TableCell className={classes.tableCell}>{moment(n.createdAt).format('DD MMM YYYY')}</TableCell>*/}

                                                            {/*<TableCell className={classes.tableCell}>view</TableCell>*/}

                                                        </TableRow>
                                                    </Tooltip>
                                                </React.Fragment>
                                            );
                                        })}
                                    </TableBody>
                                </Table>}
                            </div>

                            <TablePagination
                                classes={{toolbar: classes.tablePaginationToolbar}}
                                rowsPerPageOptions={[20, 50, 200]}
                                component="div"
                                count={accountsPagination.totalItem}
                                rowsPerPage={pagination.size}
                                page={pagination.page}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangePageSize}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

export default withStyles({ ...extendedFormsStyle, ...styles })(inject('dashboardStore', 'commonStore', 'userStore')(withRouter(observer(Accounts))));
