import {
    getAccountListApi, getSnapshotListApi, keepAliveApi, saveCsvApi, saveSnapshotCsvApi, saveWatchTowerCsvApi,
} from "../dataServices/dashboardData.service";

const getAccountList = (search, page, size) => {
    return getAccountListApi(search, page, size);
};

const keepAlive = (listenKey) => {
    return keepAliveApi(listenKey);
}

const saveCsv = (wtId, source) => {
    return saveCsvApi(wtId, source);
}

const saveWatchTowerCsv = (search, page, size) => {
    return saveWatchTowerCsvApi(search, page, size);
}

const getSnapshotList = (search, page, size) => {
    return getSnapshotListApi(search, page, size);
};

const saveSnapshotCsv = (search, page, size) => {
    return saveSnapshotCsvApi(search, page, size);
}

export {
    getAccountList,
    keepAlive,
    saveCsv,
    saveWatchTowerCsv,
    getSnapshotList,
    saveSnapshotCsv,
};
