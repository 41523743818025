import requests from "../http";

// manage user
const getListManageUserApi = () => {
    return requests
        .get(`/user/manager/list-user`);
};

const getAllUserListApi = () => {
    return requests.get(`/user/list`);
}

// manage api
const getUserListByWatchTowerIdApi = (watchTowerId) => {
    return requests.get(`/user/list/${watchTowerId}`);
}

const assignUserToWatchTowerIdApi = (data) => {
    return requests.put(`/user/manager/assign-watch-tower`, data);
}

const retrieveUserFromWatchTowerIdApi = (data) => {
    return requests.del(`/user/manager/retrieve-watch-tower`, data);
}


export {
    getListManageUserApi,
    getAllUserListApi,
    getUserListByWatchTowerIdApi,
    assignUserToWatchTowerIdApi,
    retrieveUserFromWatchTowerIdApi,
};
