export const convertPagingResponse = data => {
    const pagination = {
        page: data.data.page ? data.data.page : 0,
        items: data.data.content,
        size: data.data.size ? data.data.size : 5,
        totalPage: data.data.totalPages ? data.data.totalPages : 0,
        totalItem: data.data.totalElements ? data.data.totalElements : 0,
    };
    return pagination;
};

export const ADMIN_ROLE = ['ADMIN', 'MANAGER'];

export const dashboardStatus = ['ACTIVE', 'BALANCE DIFF', 'LIQUIDATED', 'PAUSE'];
export const fundTypeText = ['SNAP', 'ALPHA'];
export const telegramSettings = [
    {key: 'priceOverTenPercent', name: 'Market Price = Trigger Price + 10%'},
    {key: 'priceOverFivePercent', name: 'Market Price = Trigger Price + 5%'},
    {key: 'priceEqual', name: 'Market Price = Trigger Price (liquidation)'},
    {key: 'statusChangeActiveToBalDiff', name: 'Status change (active -> bal.diff)'},
    {key: 'statusChangeActiveToPause', name: 'Status change (active -> pause)'},
    {key: 'statusChangeAnyToActive', name: 'Status change (any -> active)'},
];

export const exchangeList = [
    {
        code: 'BINANCE',
        name: 'Binance'
    },
    {
        code: 'OKX',
        name: 'OKX'
    },
    {
        code: 'BYBIT',
        name: 'Bybit'
    },
];

export const FUND_TYPE = [
    {
        code: '0',
        name: 'Snap'
    },
    {
        code: '1',
        name: 'Alpha'
    },
];

export const targetAsset = [
    {
        code: 'usdt',
        name: 'USDT'
    },
    {
        code: 'usdc',
        name: 'USDC'
    }
];

export const convertStatusText = (key) => {
    if (key > dashboardStatus.length) return '';
    return dashboardStatus[key];
};

export const SOURCE_EXCHANGE = {
    'BINANCE': 0,
    'OKX': 2,
    'BYBIT': 4,
}

export const updateSourceExchange = (exchange) => {
    switch (exchange) {
        case 'BINANCE': return 0;
        case 'OKX': return 2;
        case 'BYBIT': return 4;
        default: return 0;
    }
}

export const toMoneyValue = (value) => {
    return (value * 1).toLocaleString("en-US", { minimumFractionDigits: 4, maximumFractionDigits: 4 });
}

export const reduceMaskText = (str) => {
    if (!str) return '';
    const firstIndex = str.indexOf('*');
    const lastIndex = str.lastIndexOf('*');
    return str.substring(0, firstIndex) + '*****' + str.substring(lastIndex);
};

export const dynamicSort = (property, order) => {
    const sortOrder = order === 'asc' ? 1 : -1;
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        const result = (a[property]*1 < b[property]*1) ? -1 : (a[property]*1 > b[property]*1) ? 1 : 0;
        return result * sortOrder;
    }
}

export const dynamicSortCustom = (property, order, isNumber) => {
    const sortOrder = order === 'asc' ? 1 : -1;
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        // equal items sort equally
        if (a[property] === b[property]) return 0;

        // nulls sort after anything else
        if (a[property] === null || a[property] === undefined) return 1;
        if (b[property] === null || b[property] === undefined) return -1;

        if (isNumber) {
            const result = (a[property]*1 < b[property]*1) ? -1 : (a[property]*1 > b[property]*1) ? 1 : 0;
            return result * sortOrder;
        }
        const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

export const convertUserSettingToList = (setting) => {
    const arr = [];
    if (!setting) return arr;
    if (setting.priceOverTenPercent) arr.push('priceOverTenPercent');
    if (setting.priceOverFivePercent) arr.push('priceOverFivePercent');
    if (setting.priceEqual) arr.push('priceEqual');
    if (setting.statusChangeActiveToBalDiff) arr.push('statusChangeActiveToBalDiff');
    if (setting.statusChangeActiveToPause) arr.push('statusChangeActiveToPause');
    if (setting.statusChangeAnyToActive) arr.push('statusChangeAnyToActive');
    return arr;
};

export const convertUserSettingListToObj = (settingArr, teleGroupId) => {
    const request = {
        priceOverTenPercent: settingArr.includes('priceOverTenPercent') ? true : false,
        priceOverFivePercent: settingArr.includes('priceOverFivePercent') ? true : false,
        priceEqual: settingArr.includes('priceEqual') ? true : false,
        statusChangeActiveToBalDiff: settingArr.includes('statusChangeActiveToBalDiff') ? true : false,
        statusChangeActiveToPause: settingArr.includes('statusChangeActiveToPause') ? true : false,
        statusChangeAnyToActive: settingArr.includes('statusChangeAnyToActive') ? true : false,
        groupId: teleGroupId,
    };
    return request;
};

export const convertDisplayText = (str, splitChar) => {
    if (!str) return "";
    const arr = str.split(splitChar);
    arr[0] = upperFirstLetter(arr[0]);
    return arr.join(" ");
};

export const convertFundIdToText = (description, fundType) => {
    if (description === 'Grand Total' || fundType === null || fundType === undefined) return '';
    return fundTypeText[fundType];
}

export const upperFirstLetter = (str) => {
    if (!str) return "";
    const arr = str.split("");
    arr[0] = arr[0].toUpperCase();
    return arr.join("");
};

export const getFieldFromListByKey = (list, field, key, value) => {
    const selectedItem = list.find(item => item[key] === value);
    if (selectedItem) {
        return selectedItem[field];
    }
    return value;
};

export const checkParagraphNotContainsStrings = (para, strings) => {
    let valid = true;
    strings.forEach(str => {
        const reg = new RegExp(str);
        if (reg.test(para)) {
            valid = false;
        }
    });
    return valid;
};

export const downloadURI = (uri, name) => {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
