import UserRegisterForm from "./form/userRegister";
import { decorate, observable, action } from "mobx";
import LoginForm from "./form/login";
import {
    login,
    register,
    forgotPassword,
    resetPassword
} from "../services/domainServices/authDomain.service";
import stores from ".";
import ForgotPasswordForm from "./form/forgotPassword";
import ResetPasswordForm from "./form/resetPasswordRegister";

class AuthStore {
    constructor() {
        this.userRegisterForm = new UserRegisterForm();
        this.loginForm = new LoginForm();
        this.forgotPasswordForm = new ForgotPasswordForm();
        this.resetPasswordForm = new ResetPasswordForm();
    }
    toggleShowPasswordUser() {
        if (this.userRegisterForm.$("password").get("type") === "text") {
            this.userRegisterForm.$("password").set("type", "password");
        } else {
            this.userRegisterForm.$("password").set("type", "text");
        }
    }

    toggleShowConfirmPasswordUser() {
        if (
            this.userRegisterForm.$("confirmPassword").get("type") ===
            "text"
        ) {
            this.userRegisterForm
                .$("confirmPassword")
                .set("type", "password");
        } else {
            this.userRegisterForm.$("confirmPassword").set("type", "text");
        }
    }

    toggleShowResetPassword() {
        if (this.resetPasswordForm.$("password").get("type") === "text") {
            this.resetPasswordForm.$("password").set("type", "password");
        } else {
            this.resetPasswordForm.$("password").set("type", "text");
        }
    }

    toggleShowConfirmResetPassword() {
        if (
            this.resetPasswordForm.$("confirmPassword").get("type") ===
            "text"
        ) {
            this.resetPasswordForm
                .$("confirmPassword")
                .set("type", "password");
        } else {
            this.resetPasswordForm.$("confirmPassword").set("type", "text");
        }
    }

    login(cred) {
        return login(cred).then(
            action(res => {
                // console.log('res', res);
                if (res.data.code === 200) {
                    stores.commonStore.setAppLoading();
                    stores.userStore.setUserToken(res.data.data, cred.rememberMe);
                    stores.userStore.getCurrentUser();
                }
                return res.data;
            })
        );
    }

    forgotPassword(data) {
        return forgotPassword(data);
    }

    resetPassword(data) {
        return resetPassword(data);
    }

    registerUser(newUser) {
        return register(newUser).then(
            action(res => {
                console.log('registerUser res', res);
                // if (res.data.code === 0) {
                    // stores.commonStore.setAppLoading();
                    // stores.userStore.setUserToken(res.data.data, true);
                    // stores.userStore.setIsNewRegister(true);
                    // stores.userStore.getCurrentUser();
                // }
                return res;
            })
        );
    }

    logout() {
        stores.userStore.logout();
    }
}

decorate(AuthStore, {
    userRegisterForm: observable,
    forgotPasswordForm: observable,
    resetPasswordForm: observable,
    toggleShowPasswordUser: action,
    toggleShowConfirmPasswordUser: action,
    toggleShowResetPassword: action,
    toggleShowConfirmResetPassword: action,
    login: action,
    forgotPassword: action,
    resetPassword: action,
    registerUser: action,
    logout: action
});

export default new AuthStore();
