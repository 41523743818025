import React from "react";
import { withRouter } from 'react-router-dom';
import Datetime from "react-datetime";
import * as moment from "moment-timezone/builds/moment-timezone-with-data";

// react component for creating dynamic tables
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {cardTitle} from "../../assets/jss/material-dashboard-pro-react";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import {LibraryBooks} from "@material-ui/icons";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import CardBody from "../../components/Card/CardBody";
import Loading from "../../components/Loading/Loading";
import {convertFundIdToText, reduceMaskText, toMoneyValue, upperFirstLetter} from "../../services/utils";
import GridContainer from "../../components/Grid/GridContainer";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "10px",
        marginBottom: "0px"
    },
    header: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    newBtn: {
        marginTop: '15px',
        marginLeft: 'auto'
    },
    status: {
        borderRadius: '3px',
        textAlign: 'center',
        padding: '5px 10px',
        color: 'white'
    },
    sentStatus: {
        backgroundColor: '#00acc1'
    },
    closedStatus: {
        backgroundColor: 'gray'
    },
    expiredStatus: {
        backgroundColor: 'red'
    },
    tableCell: {
        fontSize: '1rem',
        padding: '4px 20px'
    },
    tablePaginationToolbar: {
        height: '48px',
        minHeight: '48px',
        paddingRight: '2px',
    },
    dateTimeContainer: {
        "& .rdtPicker": {
            marginTop: "20px!important"
        }
    }
};

const rows = [
    { id: 'id', numeric: false, disablePadding: false, label: 'Id', disableSort: false, isNumber: true },
    { id: 'description', numeric: false, disablePadding: false, label: 'Description', disableSort: false },
    { id: 'initialCap', numeric: true, disablePadding: false, label: 'Initial Cap', disableSort: false },
    { id: 'balance', numeric: true, disablePadding: false, label: 'Spot Balance (USDT)', disableSort: false, isNumber: true },
    // { id: 'pnlusdt', numeric: false, disablePadding: false, label: 'PnL (%)', disableSort: false },
    { id: 'fbalance', numeric: true, disablePadding: false, label: 'Future Balance (USDT)', disableSort: false, isNumber: true },
    { id: 'sumBalance', numeric: true, disablePadding: false, label: 'Sum Balance (USDT)', disableSort: false, isNumber: true },
    // { id: 'pnl', numeric: true, disablePadding: false, label: 'Sum PnL (USDT / %)', disableSort: false, hasPercent: true, isNumber: true},
    { id: 'sumPnlusdt', numeric: true, disablePadding: false, label: 'Sum PnL (USDT)', disableSort: false, hasPercent: false, isNumber: true},
    // { id: 'fpnl', numeric: false, disablePadding: false, label: 'Future PnL (Toggle)', disableSort: true , hasPercent: true},
    // { id: 'fpnlusdt', numeric: false, disablePadding: false, label: 'Future PnL (%)', disableSort: false },
    { id: 'ftotalUnrealizedProfit', numeric: true, disablePadding: false, label: 'Total Unrealized Profit', disableSort: false, isNumber: true },
    { id: 'totalOpenOrder', numeric: false, disablePadding: false, label: 'Total Spot Orders', disableSort: false, isNumber: true },
    { id: 'fopenPosition', numeric: false, disablePadding: false, label: 'Future Total Positions', disableSort: false, isNumber: true },
    { id: 'fopenOrder', numeric: false, disablePadding: false, label: 'Future Total Orders', disableSort: false, isNumber: true },
    { id: 'exchange', numeric: false, disablePadding: false, label: 'Account', disableSort: false },
    { id: 'fund', numeric: false, disablePadding: false, label: 'Fund', disableSort: false },
    { id: 'address', numeric: false, disablePadding: false, label: 'Address', disableSort: true },
    // { id: 'action', numeric: false, disablePadding: false, label: 'Actions', disableSort: true },
];

class Snapshot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPnlPercentSpot: false,
            showPnlPercentFuture: false,
            submittingSend: false,
        }
    }

    componentDidMount() {
        this.props.dashboardStore.ssPagination.fromDate = moment().valueOf();
        this.getSnapshots();
    }

    getSnapshots = () => {
        const { getSnapshotList, ssPagination } = this.props.dashboardStore;
        const { page, size, fromDate } = ssPagination;
        const paramRequest = 'date=' + fromDate;
        console.log('paramRequest', paramRequest)
        getSnapshotList(paramRequest, page, size);
    }

    styleStatusColor = (id, pnl) => {
        if (!id) return {background: "#FFEF88", cursor: 'not-allowed'}
        if (pnl < 0) return {background: "rgba(246, 70, 93, 0.15)", cursor: 'pointer'};
        return {background: "rgba(14, 203, 129, 0.15)", cursor: 'pointer'};
    }

    handleSort = (sort, isNumber) => () => {
        this.props.dashboardStore.changeSortOfSnapshotPagination(sort, isNumber);
        // this.getListingsList(); // sort local
    }

    handleExportSnapshot = () => {
        this.setState({ submittingSend: true });
        const { downloadSnapshot, ssPagination } = this.props.dashboardStore;
        const { page, fromDate } = ssPagination;
        const paramRequest = 'date=' + fromDate;
        downloadSnapshot(paramRequest, page, 1000);
        this.setState({ submittingSend: false });
    }

    handleShowPercent = id => () => {
        if (id === 'pnl') this.setState({showPnlPercentSpot: !this.state.showPnlPercentSpot});
        else if (id === 'fpnl') this.setState({showPnlPercentFuture: !this.state.showPnlPercentFuture});
    }

    validFromDate = (currentDate) => {
        const { toDate } = this.props.dashboardStore.ssPagination;
        if (toDate) {
            const toDateMoment = moment(toDate);
            return currentDate.isBefore(toDateMoment);
        }
        return true;
    };

    changeFromDateFilter = e => {
        if (e) {
            this.props.dashboardStore.ssPagination.fromDate = e.valueOf();
            this.getSnapshots();
        }
    }

    render() {
        const { classes } = this.props;
        const { snapshotPagination, ssPagination, isLoading } = this.props.dashboardStore;

        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon className={classes.header}>
                            <CardIcon color="primary">
                                <LibraryBooks />
                            </CardIcon>
                            <h3 className={classes.cardIconTitle}>Watch Tower Snapshot</h3>
                        </CardHeader>
                        <CardBody>
                            <h3> *Watch Tower snapshot are captured every day at 12:05 am SGT</h3>
                            <GridContainer className={classes.mb20}>
                                <GridItem xs={2}>
                                    <div className={classes.dateTimeContainer}>
                                        <Datetime
                                            closeOnSelect={true}
                                            value={ssPagination.fromDate}
                                            onChange={this.changeFromDateFilter}
                                            isValidDate={this.validFromDate}
                                            timeFormat={false}
                                            renderInput={(props) =>
                                                <CustomInput
                                                    labelText='Date'
                                                    formControlProps={{fullWidth: true}}
                                                    inputProps={props}/>}
                                        />
                                    </div>
                                </GridItem>
                                <GridItem xs={5}>
                                    <Button color="facebook" onClick={this.handleExportSnapshot} loading={this.state.submittingSend} disabled={this.state.submittingSend}>
                                        Export
                                    </Button>
                                </GridItem>
                            </GridContainer>
                            <div style={{ overflow: 'auto', height: '78vh' }}>
                                {isLoading ? <Loading /> : <Table aria-labelledby="tableTitle" padding="default">
                                    <TableHead style={{ backgroundColor: '#fff', position: "sticky", top: 0 }}>
                                        <TableRow>
                                            {rows.map(
                                                row => (
                                                    <TableCell key={row.id}
                                                               className={classes.tableCell}
                                                               align={row.numeric ? 'right' : 'left'}
                                                               padding={row.disablePadding ? 'none' : 'default'}
                                                               sortDirection={ssPagination.sort === row.id ? ssPagination.order : false}
                                                    >
                                                        {!row.disableSort ? <Tooltip
                                                            title="Sort"
                                                            placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                                            enterDelay={300}
                                                        >
                                                            <TableSortLabel
                                                                active={ssPagination.sort === row.id}
                                                                direction={ssPagination.order}
                                                                onClick={this.handleSort(row.id, row.isNumber)}
                                                            >
                                                                {row.label}
                                                            </TableSortLabel>
                                                        </Tooltip> : row.hasPercent ? <TableSortLabel onClick={this.handleShowPercent(row.id)}>{row.label}</TableSortLabel> : row.label}
                                                    </TableCell>
                                                ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/*{accountsPagination && accountsPagination.items &&*/}
                                        {/*    <TableContent classes={classes} history={this.props.history} listenKey={listenKey} isFeedKilled={false} dataList={accountsPagination.items}></TableContent>*/}
                                        {/*    <Accounts accountList={accountsPagination.items}></Accounts>*/}
                                        {/*}*/}

                                        {/*{accountsPagination && accountsPagination.items && <WebSocket accountList={accountsPagination.items}></WebSocket>}*/}

                                        {snapshotPagination && snapshotPagination.items && snapshotPagination.items.map((n, index) => {
                                            return (
                                                <React.Fragment key={index++}>
                                                    <Tooltip title=''>
                                                        <TableRow hover tabIndex={-1} style={this.styleStatusColor(n.id, n.sumPnlusdt)}>
                                                            <TableCell className={classes.tableCell}>{n.id}</TableCell>
                                                            <TableCell className={classes.tableCell}>{n.description}</TableCell>
                                                            <TableCell align={"right"} className={classes.tableCell}>{toMoneyValue(n.initialCap)}</TableCell>

                                                            <TableCell align={"right"} className={classes.tableCell}>{toMoneyValue(n.balance)}</TableCell>
                                                            <TableCell align={"right"} className={classes.tableCell}>{toMoneyValue(n.fbalance)}</TableCell>

                                                            <TableCell align={"right"} className={classes.tableCell}>{toMoneyValue(n.sumBalance)}</TableCell>

                                                            {/*<TableCell className={classes.tableCell}>{this.state.showPnlPercentSpot ? (toMoneyValue(n.sumPnl) + " %") : (toMoneyValue(n.sumPnlusdt) + " USDT")}</TableCell>*/}
                                                            {/*<TableCell align={"right"} className={classes.tableCell}>{this.state.showPnlPercentSpot ? (toMoneyValue(n.sumPnl)) : (toMoneyValue(n.sumPnlusdt) )}</TableCell>*/}
                                                            <TableCell align={"right"} className={classes.tableCell}>{(toMoneyValue(n.sumPnlusdt) )}</TableCell>

                                                            {/*<TableCell className={classes.tableCell}>{n.pnl}</TableCell>*/}

                                                            <TableCell align={"right"} className={classes.tableCell}>{toMoneyValue(n.ftotalUnrealizedProfit)}</TableCell>

                                                            {/*<TableCell className={classes.tableCell}>{this.state.showPnlPercentFuture ? ((n.fpnl ? n.fpnl : 0) + " %") : ((n.fpnlusdt ? n.fpnlusdt : 0) + " USDT")}</TableCell>*/}
                                                            {/*<TableCell className={classes.tableCell}>{n.fpnl}</TableCell>*/}
                                                            <TableCell className={classes.tableCell} >{n.totalOpenOrder}</TableCell>
                                                            <TableCell className={classes.tableCell} >{n.fopenPosition}</TableCell>
                                                            <TableCell className={classes.tableCell} >{n.fopenOrder}</TableCell>

                                                            <TableCell className={classes.tableCell}>{upperFirstLetter(n.exchange)}</TableCell>
                                                            <TableCell className={classes.tableCell}>{convertFundIdToText(n.description, n.fundType)}</TableCell>

                                                            <TableCell className={classes.tableCell}>{reduceMaskText(n.apiKey)}</TableCell>

                                                            {/*<TableCell className={classes.tableCell}>{moment(n.createdAt).format('DD MMM YYYY')}</TableCell>*/}

                                                            {/*<TableCell className={classes.tableCell}>view</TableCell>*/}

                                                        </TableRow>
                                                    </Tooltip>
                                                </React.Fragment>
                                            );
                                        })}
                                    </TableBody>
                                </Table>}
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}
export default withStyles({ ...extendedFormsStyle, ...styles })(inject('dashboardStore', 'commonStore', 'userStore')(withRouter(observer(Snapshot))));
