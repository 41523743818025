export default {
    "MSG_SUCCESSFUL": "Successful",
    "MSG_DEL_SUCCESSFUL": "Delete successful!",
    "MSG_LOGIN_FAILED": "Incorrect username or password",
    "MSG_USER_ALREADY_EXIST": "User already existed",
    "REGISTER.SIGNUP_SUCCESS": "You Signed up Successfully",
    "MSG_ACCOUNT_CLIENT_NOT_EXIST": "Account client not found",
    "MSG_CHANGE_PASSWORD_CURRENT_PASSWORD_NOT_VALID": "Your current password incorrect",
    "MSG_INVALID_INPUT": "Some fields are missing or have errors. Please correct them and try again.",
    "MSG_STOP_PRICE_OVER_PRICE": "Sell price must be smaller then target sell value",
    "MSG_API_KEY_ALREADY_EXISTED": "Your api key is already used with this asset",
    "LOGIN.SUCCESS": "Login Successful",
    "RESET_PASSWORD.SUCCESS": "Password Reset",
    "COMMON.UNKNOWN_ERROR": "Something went wrong. We apologize for this issue. Please try again later.",
    "USER.ROLE.REGISTER": "We apologize but the Web App. is not available for register. Please contact your admin."
};
